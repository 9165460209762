<template>
  <section class="biography__">
    <div class="d-flex justify-content-center">
      <div class="info__v">
        <div class="--sketch1__">
          <div data-depth="0.2">
            <div class="sketch1__">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="525.517"
                height="634.122"
                viewBox="0 0 525.517 634.122"
              >
                <path
                  style="fill: #ffce00;"
                  d="M479.293,1639.507c45.059-153.166-89.6-245.765-200.453-184.079s26.917,166.652-52.445,210S108.934,1805,156.942,1850.964s-163.158,138.522-97.259,203.1,201.024-85.809,332.977-76.954,144.707-105.279,175.762-179.524S434.234,1792.673,479.293,1639.507Z"
                  transform="translate(-46.821 -1436.105)"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="--profile__v">
          <div data-depth="0.3">
            <div class="profile__v">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="422.608"
                viewBox="0 0 422.608 519.911"
                style="transform: translate(70px, 0px);"
              >
                <g transform="translate(-0.003 -0.004)">
                  <path
                    style="fill: #c67a60;"
                    d="M455.077,514.208c-20.465-16.388-34.837-37.065-41.51-62.425-1.954-7.434-6.7-9.219-12.255-11.4.66-2.834,10.494-2.082,2.974-8.389a9.843,9.843,0,0,1-2.846-10.744c5.094-11.862,2.2-24.325,2.546-36.491.3-10.349,1.856-19.835,8.359-28.316,6.994-9.118,9.59-20.156,11.52-31.189,2.082-11.895,8.894-17.447,20.647-17.67,3.343-.065,6.941.3,9.968-.892,15.585-6.12,28.795-3.735,39.236,9.941,13.762,2.867,26.5-2.314,39.348-5.475,10.878-2.676,17.286.723,20.376,11.175a136.044,136.044,0,0,0,19.032,38.908c8.624,12.451,5.421,27.061,4.913,40.785-.271,7.333,3.06,3.916,5.906,3.673,9.629,11.369-.755,23.341,1.091,34.941-7.619,2.569-8.326,9.849-10.381,15.921-6.947,20.391-16.742,39.2-34.486,51.85-9.126,6.518-5.177,14.812-6.84,22.4-7.271-6.658-14.741-7.167-24.646-4.71C489.025,530.81,470.395,528.541,455.077,514.208Z"
                    transform="translate(-281.661 -213.248)"
                  />
                  <path
                    style="fill: #1c1c1c;"
                    d="M440.516,108.657c-4.392.651-9.5-1.451-9.813-4.69-1.472-15.556-10.67-7.952-18.735-7.979-25.214-.1-32.794,13.409-42.872,35.721-10.572,23.4-12.49,44.719-11.895,68.265.119,4.856,1.2,10.9-7.19,9.451-8.291-11-6.9-24.682-10.262-37.053,4.163-3.8,8.193-7.419,5.837-14.164-4.55-13.04-1.969-26.648-2.584-40-.621-13.557,2.638-27.358-11.5-37.766-7.631-5.617-6.046-14.571,2.8-21,8.428-6.123,18.419-9.058,28.521-13.468-5.76-6.718-12.986-3.149-18.071-6.905-4.142-3.06-9.156-5.07-7.532-11.419,1.35-5.287,5.858-6.022,10.548-6.382,14.378-1.109,28.548,1.656,43.274,3.652-.651-2.54-.616-3.806-1.222-4.544-4.654-5.65-18.25-5.171-15.032-14.973,2.867-8.734,14.03-4.368,21.114-3.866,36.158,2.489,72.295,5.817,102.038,30.285,3.97,5.674,3.188,11.693.892,17.593C487.959,77.585,471.717,100.53,440.516,108.657Z"
                    transform="translate(-229.485 0)"
                  />
                  <path
                    style="fill: #feeaec;"
                    d="M379.883,1215.61c3.714,2.3,7.607,1.189,11.47.595,11.773,17.033,23.692,33.282,43.649,42.593,43.776,20.427,93.152,6.016,117.76-35.792a49.317,49.317,0,0,1,4.627-6.964c4.08.1,4.359,4.562,7.086,6.212,5.713,4.44,4.9,10.908,5,16.95.229,13.977-.63,27.989.256,41.9,1.035,16.228-6.492,25.485-21.238,29.482-49,13.29-96.944,30.091-145.889,43.547-8.08,2.221-17.355,6.272-24.887-2.379-4.553-10.759-2.81-22.1-2.9-33.208q-.339-40.315.021-80.636C374.9,1230.193,373.829,1222.036,379.883,1215.61Z"
                    transform="translate(-263.174 -854.117)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M596.037,1127.105c2.126,4.086.348,7.541-1.784,10.934-19.041,30.5-46.9,45.9-82.438,45.251-34.724-.64-62.032-15.963-79.976-46.539-1.769-3.018-2.941-5.983-1.832-9.477,20.882-1.588,36.874-9.581,41.829-32.039,19.314,4.425,37.532-3.182,56.239-5.287,9.584-1.08,18.972-2.9,26,6.159C560.739,1116.382,575.078,1126.24,596.037,1127.105Z"
                    transform="translate(-301.828 -765.181)"
                  />
                  <path
                    style="fill: #8d2b26;"
                    d="M1084.594,1302.823c46.093,27.358,61.441,69.832,59.606,121-.312,8.689-3.464,11.577-12.047,11.425-34.052-.595-68.117-.651-102.175-.892-5.278-6.56-1.986-12.51,1.19-18.583,17.325-33.193,35.346-66.07,44.5-102.841C1076.8,1308.44,1077.457,1302.46,1084.594,1302.823Z"
                    transform="translate(-721.733 -915.383)"
                  />
                  <path
                    style="fill: #8d2b26;"
                    d="M113.852,1435.439c-33.517.241-67.04.235-100.551.892-9.269.178-13.046-2.9-13.245-12.314-1.079-50.408,14.336-92.415,59.5-119.675,6.6-.654,6.685,4.758,7.61,8.761,8.121,35.09,23.837,66.891,41.481,97.94C112.829,1418.394,119.865,1425.668,113.852,1435.439Z"
                    transform="translate(0 -916.424)"
                  />
                  <path
                    style="fill: #edd3d6;"
                    d="M387.863,1353.373c42.423-6.908,81.965-24.022,123.2-35.158q23.32-6.3,46.444-13.308c10.013-3.033,15.333-8.784,14.625-20.727-1.222-20.617-.595-41.335-.732-62.029q1.749-3.345,3.506-6.691c6.3,6.159,5.65,14.229,5.7,21.934.2,34.5.045,69.017.1,103.528.021,11.22-3.833,21.75-5.222,32.711l-188.877-.089C386.186,1366.767,381.2,1359.71,387.863,1353.373Z"
                    transform="translate(-270.1 -854.011)"
                  />
                  <path
                    style="fill: #ea5546;"
                    d="M258.461,1343.682q-.618,10.084-1.237,20.165a4.4,4.4,0,0,1-2.676-.511c.3-7.732-3.916-13.917-7.47-20.079-19.731-34.228-37.921-69.032-44.633-108.619-.155-.892-1.439-1.609-2.195-2.409,16.356-29.523,30.5-36.059,57.161-26.416Q257.932,1274.748,258.461,1343.682Z"
                    transform="translate(-140.699 -844.321)"
                  />
                  <path
                    style="fill: #ea5546;"
                    d="M1025.577,1364.48q-.3-79.1-.577-158.17c23.493-8.547,38.748-3.131,51.874,18.247,1.716,2.8,4.1,5.177,6.179,7.75-11.333,37.3-26.686,72.886-45.079,107.224-4.1,7.657-11.247,14.274-9.534,24.3A6.434,6.434,0,0,1,1025.577,1364.48Z"
                    transform="translate(-720.19 -844.865)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M709.64,183.821c25.854-14.782,47.137-33.6,55.312-63.724,1.154-4.249,1.421-8.74,2.1-13.117C786,130.059,810.512,150,804.666,185.594c-2.519,15.333.268,31.522-2.031,46.917-1.323,8.85,2.15,11.743,8.071,14.985-5.011,5.974-5.216,14.494-10.67,20.221-4.7,4.562-.158,12.454-9.516,19.353,0-15.761-.419-29.056.143-42.322.393-9.332-1.24-16.653-8.133-24.087-9.123-9.846-13.459-22.922-16.382-36.181-1.7-7.732-4.859-11-14.042-7.714C738.592,181.627,724.5,185.754,709.64,183.821Z"
                    transform="translate(-498.609 -75.164)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M653.932,1042.01c-22.49-7.976-43.339,4.071-65.065,5.537-7.179.485-14.571,4.318-17.176-6.411l3.408-29.086c21.985,8.6,43.812,13.864,67.266,3.893,13.3-5.65,2.9,11.565,10.331,12.707Q653.316,1035.331,653.932,1042.01Z"
                    transform="translate(-401.682 -711.09)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M1003.692,599.758c2.548-7.28-6.2-20.54,10.67-20.221,17.929,15.987,13.272,43.321-9.385,55.065-8.249-1.255-5.189-6.97-3.622-10.931C1004.465,615.793,1004.444,607.867,1003.692,599.758Z"
                    transform="translate(-702.259 -407.191)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M347.436,579.64c10.019-.021,6.45,7.955,7.3,12.968,1.353,7.967,2.01,16.058,2.959,24.087,1.978,6.013,18.58,12.748-.868,17.7C335.383,621.127,331.244,596.995,347.436,579.64Z"
                    transform="translate(-237.179 -407.267)"
                  />
                  <path
                    style="fill: #faebea;"
                    d="M679.788,837.28c-16.822,14.848-35.956,15.4-61.738,0Z"
                    transform="translate(-434.256 -588.292)"
                  />
                  <path
                    style="fill: #50242b;"
                    d="M492.9,493.37c12.234-18.919,26.764-18.7,45.567,1.005C521.525,488.7,508.5,481.582,492.9,493.37Z"
                    transform="translate(-346.322 -336.827)"
                  />
                  <path
                    style="fill: #8d3937;"
                    d="M746.607,582.79c9.037,21.033,8.4,44.371-5.927,55.7C756.093,617.809,739.092,600.451,746.607,582.79Z"
                    transform="translate(-520.419 -409.481)"
                  />
                  <path
                    style="fill: #4a242a;"
                    d="M837.922,492.057c-14.021-5.409-26.428-9.394-41.662.648C809.654,473.819,824.954,474.152,837.922,492.057Z"
                    transform="translate(-559.471 -336.263)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M679.976,803.708c-18.517.874-54.565.3-74.076,1.169C638.739,788.982,659.457,796.116,679.976,803.708Z"
                    transform="translate(-425.719 -558.829)"
                  />
                  <path
                    style="fill: #6d2e30;"
                    d="M562.42,595.709c-10-10.274-18.7-7.369-28.126,3.7-.633-10.277,4.478-14.42,10.81-15.728C553.725,581.9,559.518,586.743,562.42,595.709Z"
                    transform="translate(-375.37 -409.847)"
                  />
                  <path
                    style="fill: #63282a;"
                    d="M846.989,587.45c-4.868-7.595-13.382-7.8-22.779-1.755C824.6,571.874,846.017,571.484,846.989,587.45Z"
                    transform="translate(-579.109 -404.286)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M455.63,836.126s3.893,42.441,82.935,76.7c21.027-8.567,35.771-22.9,40.1-26.466,6.62-5.457,30.177-41.284,32.515-53.744-19.859,22.2-32.116,48.671-73.008,48.671-29.984,0-42.105-7.53-55.775-14.5C469.746,860.335,473.41,859.708,455.63,836.126Z"
                    transform="translate(-320.135 -585.018)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M695.24,885.193s6.813,13.629,13.043,16.356C715.292,896.494,718.394,885,718.394,885S695.826,888.114,695.24,885.193Z"
                    transform="translate(-488.491 -621.821)"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="--name__v">
            <div data-depth="0.3">
              <div class="name__v">
                <span class="name__">VIPU</span>
              </div>
            </div>
          </div>
          <div class="btn__info_v">
            <router-link class="btn btn__info" to="/biography/vipu"
              >MORE</router-link
            >
          </div>
        </div>
      </div>
      <div class="about__us">
        <div class="--about__us">
          <div data-depth="0.2">
            <svg width="396" height="88" viewBox="0 0 396 88">
              <text class="about__us_title" transform="translate(1 70)">
                <tspan x="0" y="0">ABOUT US</tspan>
              </text>
            </svg>
            <div class="box__lines_biography">
              <div class="box__line_1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="184"
                  height="5"
                  viewBox="0 0 184 5"
                >
                  <line x2="179" transform="translate(2.5 2.5)" />
                </svg>
              </div>
              <div class="box__line_2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="229"
                  height="5"
                  viewBox="0 0 229 5"
                >
                  <line x2="224" transform="translate(2.5 2.5)" />
                </svg>
              </div>
            </div>
            <svg width="410" height="122" viewBox="0 0 410 122">
              <text class="about__us_text" transform="translate(205 48)">
                <tspan x="-204.925" y="0">We love to make</tspan>
                <tspan x="-203.675" y="61">dreams happen!</tspan>
              </text>
            </svg>
          </div>
        </div>
      </div>
      <div class="info__b">
        <div class="--info__b">
          <div data-depth="0.2">
            <div class="sketch2__">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="662.601"
                height="757.062"
                viewBox="0 0 662.601 757.062"
              >
                <path
                  style="fill: #ffce00;"
                  d="M604.419,1557.3c45.058-153.166-214.73-163.554-325.579-101.868s-39.438,118.656-118.8,162.006,4.724,58.455,52.731,104.421-55.787,250.21,10.112,314.789,89.2-64.991,221.155-56.136-43.078-150.015-12.024-224.26S559.361,1710.462,604.419,1557.3Z"
                  transform="translate(114.054 2030.28) rotate(-161)"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="--profile__b">
          <div data-depth="0.3">
            <div class="profile__b">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="491.52"
                viewBox="0 0 491.52 511.307"
                style="transform: translate(50px, 8px);"
              >
                <g transform="translate(-1288.821 -187.592)">
                  <g transform="translate(1288.821 187.592)">
                    <path
                      style="fill: #c67a60;"
                      d="M541.412,1377.646,523.27,1384.4l18.675,64.739,156.248,24.277,8.715-90.261-46.052-13.073-101.607,4a57.633,57.633,0,0,0-17.837,3.57Z"
                      transform="translate(-366.668 -1003.828)"
                    />
                    <path
                      style="fill: #262626;"
                      d="M486.152,492.361c-22.263-41.4-36.487-59.708-43.754-94.649-4.324-6.419-6.389-13.509-7.317-21.21-3.262-27.117-5.546-54.249-3.292-81.579-1.8-21.916-12.869-16.34-14.847-38.238-2.993-33.219,51.415-69.611,71.868-96.043,116.609-47.863,142.856,35.234,178.481,83.626-2.236,18.926-6.36,37.634-9.631,56.437-.943,27.833-6.306,55.132-9.595,82.681-.649,5.453-4.319,9.385-6.315,14.141-3.765,15.637-7.054,18.474-11.672,33.857-6.928,23.14-21.111,33.776-41.695,66.63-1.685,2.993-3.891,5.6-7.135,6.7-31.2,10.558-61.618,9.972-90.752-6.874A9.754,9.754,0,0,1,486.152,492.361Z"
                      transform="translate(-292.078 -146.28)"
                    />
                    <path
                      style="fill: #00a8db;"
                      d="M358.514,1442.81c35.123,16.909,70.575,33.169,105.234,50.978,19.079,9.8,24.11,29.521,28.329,48.878.183,21.093.183,21.093-21.033,21.093q-223.881,0-447.759.266c-12.3.033-19.154-2.221-17.316-15.888,4.489-28.985,16.287-51.215,46.169-62.964,29.1-11.438,56.515-27.171,84.668-41.019,8.167,2.768,10.415,10.678,14.5,16.918,15.472,23.6,33.953,44.077,61.261,53.7,51.146,18.043,104.65,6.515,133.755-51.439C349.859,1456.271,350.817,1447.467,358.514,1442.81Z"
                      transform="translate(-3.975 -1054.792)"
                    />
                    <path
                      style="fill: #00a8db;"
                      d="M460.932,1415.763c2.328-1.6,4.72-3.115,6.973-4.815,9.179-6.922,14.126-5.985,19.48,5.806,16.038,35.374,43.347,55.306,83.471,53.962,34.644-1.161,58.173-19.177,72.52-49.979,7.892-16.933,7.643-17.058,23.972-8.437,2.095,1.1,4,2.52,5.985,3.792-10.8,37.966-31.861,67.956-71.227,78.557s-76.216,3.052-107.17-25.708c-12.674-11.779-23.487-24.911-31.514-40.261C461.327,1424.678,457.206,1420.943,460.932,1415.763Z"
                      transform="translate(-321.933 -1029.42)"
                    />
                    <path
                      style="fill: #a57367;"
                      d="M648.234,1302.68c33.163,18.525,67.358,18.372,102.241,5.653.368,7.084.8,12.788.814,21.317.054,25.438-29.311,55.737-55.964,54.468-14.165-.677-48.875-23.8-47.133-49.793C648.949,1323.07,648.174,1317.844,648.234,1302.68Z"
                      transform="translate(-454.159 -956.599)"
                    />
                    <path
                      style="fill: #a57367;"
                      d="M414.35,643c5.4,26.489,3.268,53.717,7.886,80.292,1.293,7.437,1.844,15,2.735,22.5-29.784-14.114-32.109-36.748-32.316-68.824-.093-14.159-4.507-23.044,9.93-22.781C411.786,654.348,414.195,650.694,414.35,643Z"
                      transform="translate(-274.648 -494.344)"
                    />
                    <path
                      style="fill: #a57367;"
                      d="M1168.4,759.08c6.267-32.121,4.354-65.589,15.909-96.83.668,1.26,1.03,3.193,2.053,3.657,22.248,10.068,18.082,17.394,11.187,41.129C1191.324,728.488,1187.4,747.238,1168.4,759.08Z"
                      transform="translate(-818.727 -507.833)"
                    />
                    <path
                      style="fill: #065c70;"
                      d="M1.991,1781.969c1.2,12.57,8.38,14.569,20.12,14.53,147.18-.472,294.363-.3,441.546-.3,20.91,0,20.91,0,24.442-19.689,6.09,23.427,6.09,23.421-17.358,23.421q-227.383-.018-454.762-.039C-1.765,1799.877-1.765,1799.823,1.991,1781.969Z"
                      transform="translate(0.003 -1288.624)"
                    />
                    <path
                      style="fill: #065c70;"
                      d="M450.036,1437.21c26.833,73.466,111.225,105.889,172.7,60.5,18.346-13.548,27.144-34.057,36.568-53.917,1-2.095,2.095-4.157,3.136-6.234l3.13,1.332c-13.363,37.535-33.495,69.276-75.013,79.538-39.8,9.834-76.07,2.2-107.389-25.795-16.7-14.919-28.6-33.127-39.292-52.4C445.269,1437.883,447.591,1437.422,450.036,1437.21Z"
                      transform="translate(-311.037 -1050.868)"
                    />
                    <path
                      style="fill: #c67a60;"
                      d="M671.533,624.068c-12.956,13.856-1.17-35.033-8.344-48.105-7.452-13.584-19.222-1.918-32.92,2.867a29.421,29.421,0,0,1-20.369.183c-39.088-12.869-23.807,40.225-39.052,47.884-11.914,5.985-17.936-34.036-22.927-41.111-4.788-7.628-9.023-39.75-12.177-46.226-14.6-31.16-4.3-75.279,6.285-106.521,30.843-3.035,53.286,1.879,76.851-11.63,17.861-10.256,37.269-11.519,57.619-11.639,12.408-.075,16.376,20.569,21.162,31.532,14.3,32.747,22.823,66.107,6.584,100.951C701.451,549.632,693.622,600.443,671.533,624.068Z"
                      transform="translate(-370.339 -330.914)"
                    />
                    <path
                      style="fill: #444;"
                      d="M737.52,1011.545c22.553,7.081,43.829,7.838,63.656-12.7C794.047,1022.238,754.573,1028.661,737.52,1011.545Z"
                      transform="translate(-516.798 -743.698)"
                    />
                    <path
                      style="fill: #444;"
                      d="M830.585,1102.794c-11.75,9.128-19.974,10.475-29.835,2.553C809.935,1100.319,818.428,1102.806,830.585,1102.794Z"
                      transform="translate(-561.105 -816.11)"
                    />
                    <path
                      style="fill: #3f2030;"
                      d="M944.145,635.612c9.517,1.736,18.669,4.33,25.842,11.291,2.481,2.394,4.229,5.306,2.113,8.927-2.248,3.846-5.24-1.568-9.277-3.307-7.437-3.217-15.116.045-23.2-1.5-4.968-.94-7.6-4.295-6.488-9.325C934.434,635.911,938.776,634.555,944.145,635.612Z"
                      transform="translate(-653.695 -488.937)"
                    />
                    <path
                      style="fill: #402031;"
                      d="M651.074,644.617c-.637,1.02-1.416,4-3.113,4.684-9.664,3.864-18.211,4.378-28.317,6.309-5.985,1.143-10.5-.126-7.251-6.653,3.516-7.057,26.136-15.915,33.237-13.162C649.069,637.1,651.059,639.542,651.074,644.617Z"
                      transform="translate(-428.356 -488.935)"
                    />
                    <path
                      style="fill: #7a3633;"
                      d="M809.787,790.86c7.44,11.893.3,20.309.838,28.976.554,8.772-1.888,18.9,13.06,15.383-2.93,11.1-9.292,7.182-14.781,5.656-6.728-1.885-6.713-6.3-5.267-12.333,1.419-5.914.661-12.318,1.553-18.4C806.016,804.6,807.761,799.2,809.787,790.86Z"
                      transform="translate(-562.586 -597.954)"
                    />
                    <path
                      style="fill: #4c2833;"
                      d="M690.677,756.318c-.745,5.288-.509,12.713-6.5,12.839-7.183.153-7.1-7.545-7.329-13.126-.209-5.306.392-12.57,6.225-12.7C690.15,743.185,689.563,751.1,690.677,756.318Z"
                      transform="translate(-474.261 -564.65)"
                    />
                    <path
                      style="fill: #4e2934;"
                      d="M959,756.16c-1.721,5.387-.793,13.512-8.233,12.977-5.866-.425-5.956-7.649-5.836-12.911.129-5.686.368-13.168,7.506-12.8C958.355,743.758,957.667,751.392,959,756.16Z"
                      transform="translate(-662.12 -564.708)"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="--name__b">
            <div data-depth="0.3">
              <div class="name__b">
                <span class="name__">BRAVEEN</span>
              </div>
            </div>
          </div>
          <div class="btn__info_b">
            <router-link class="btn btn__info" to="/biography/braveen"
              >MORE</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Biography",
  mounted() {
    new this.$parallax(document.querySelector(".--profile__v"));
    new this.$parallax(document.querySelector(".--about__us"));
    new this.$parallax(document.querySelector(".--info__b"));
    new this.$parallax(document.querySelector(".--sketch1__"));
    new this.$parallax(document.querySelector(".--profile__b"));
    new this.$parallax(document.querySelector(".--name__b"));
    new this.$parallax(document.querySelector(".--name__v"));
  },
};
</script>

<style>
.bg__img {
  opacity: 0.5;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.biography__ {
  padding-top: 13em;
  padding-left: 2em;
  padding-right: 2em;
}
.sketch1__ {
  position: absolute;
}
.sketch2__ {
  position: absolute;
}
.profile__v {
  position: relative;
}
.profile__b {
  position: relative;
}
.about__us {
  width: 450px;
  transform: scale(0.9) translate(75px, 160px);
}
.about__us_title {
  color: #212121;
  font-size: 72px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
}
.box__line_1 {
  transform: translate(10px, 0);
}
.box__line_2 {
  transform: translate(150px, 0);
}
.about__us_text {
  color: #212121;
  font-size: 50px;
  font-family: Montserrat-Light, Montserrat;
  font-weight: 300;
}
.name__ {
  position: relative;
  color: #212121;
  font-size: 48px;
  font-family: Montserrat-Black, Montserrat;
  font-weight: 800;
}
.info__b {
  transform: scale(0.9) translate(130px, 0px);
}
.info__v {
  transform: scale(0.9) translate(-200px, 0px);
}
.btn__info {
  position: relative;
  color: #fff;
  font-size: 18px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
  border-radius: 10px;
  background-color: #c64342;
}
.name__v {
  transform: translate(220px, 0px);
  width: fit-content;
}
.btn__info_v {
  transform: translate(241px, 0px);
  width: fit-content;
}
.name__b {
  transform: translate(180px, 0px);
  width: fit-content;
}
.btn__info_b {
  transform: translate(260px, 0px);
  width: fit-content;
}
@media only screen and (max-width: 1750px) {
  .about__us {
    transform: scale(0.8) translate(80px, 80px);
  }
  .info__b {
    transform: scale(0.8) translate(50px, -80px);
  }
  .info__v {
    transform: scale(0.8) translate(-100px, -80px);
  }
  .biography__ {
    padding-top: 14em;
  }
}
@media only screen and (max-width: 1635px) {
  .about__us {
    transform: scale(0.7) translate(85px, 20px);
  }
  .info__b {
    transform: scale(0.7) translate(0px, -140px);
  }
  .info__v {
    transform: scale(0.7) translate(-30px, -140px);
  }
  .btn__info {
    font-size: 20px;
  }
  .btn__info_v {
    transform: translate(233px, 0px);
  }
  .btn__info_b {
    transform: translate(255px, 0px);
  }
}
@media only screen and (max-width: 1500px) {
  .about__us {
    transform: scale(0.6) translate(90px, -60px);
  }
  .info__b {
    transform: scale(0.6) translate(-50px, -220px);
  }
  .info__v {
    transform: scale(0.6) translate(15px, -220px);
  }
  .btn__info {
    font-size: 25px;
  }
  .btn__info_v {
    transform: translate(230px, 0px);
  }
  .btn__info_b {
    transform: translate(250px, 0px);
  }
}
@media only screen and (max-width: 1200px) {
  .about__us {
    transform: scale(0.5) translate(95px, -170px);
  }
  .info__b {
    transform: scale(0.5) translate(-180px, -330px);
  }
  .info__v {
    transform: scale(0.5) translate(170px, -330px);
  }
  .btn__info {
    font-size: 30px;
  }
  .btn__info_v {
    transform: translate(221px, 0px);
  }
  .btn__info_b {
    transform: translate(244px, 0px);
  }
}
@media only screen and (max-width: 1050px) {
  .about__us {
    transform: scale(0.4) translate(115px, -350px);
  }
  .info__b {
    transform: scale(0.4) translate(-480px, -500px);
  }
  .info__v {
    transform: scale(0.4) translate(500px, -500px);
  }
  .btn__info {
    font-size: 35px;
  }
  .btn__info_v {
    transform: translate(213.5px, 0px);
  }
  .btn__info_b {
    transform: translate(237px, 0px);
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .about__us {
    position: absolute;
    transform: scale(0.55) translate(50px, -95px);
  }
  .info__b {
    transform: scale(0.35) translate(-380px, 280px);
  }
  .info__v {
    transform: scale(0.35) translate(380px, -260px);
  }
  .name__ {
    font-size: 80px;
  }
  .name__b {
    transform: translate(-430px, -410px);
  }
  .name__v {
    transform: translate(690px, -450px);
  }
  .btn__info {
    font-size: 50px;
  }
  .btn__info_b {
    transform: translate(-315px, -410px);
  }
  .btn__info_v {
    transform: translate(700px, -450px);
  }
  .biography__ {
    padding-top: 7.5em;
  }
}

@media only screen and (min-height: 740px) and (max-height: 840px) and (max-width: 767px) {
  .biography__ {
    padding-top: 12em !important;
  }
}

@media only screen and (min-height: 680px) and (max-height: 740px) and (max-width: 767px) {
  .biography__ {
    padding-top: 9em !important;
  }
}

@media only screen and (max-height: 640px) and (max-width: 767px) and (min-width: 350px) {
    .biography__ {
    padding-top: 5.2em;
  }
  .about__us {
    transform: scale(0.4) translate(50px,-95px)!important;
  }
  .info__b {
    transform: scale(0.27) translate(-600px,50px)!important;
  }
  .info__v {
    transform: scale(0.27) translate(600px,-500px)!important;
  }
}

@media only screen and (max-width: 399px) {
  
    .about__us {
    transform: scale(0.5) translate(35px, -95px);
  }
  .info__b {
    transform: scale(0.3) translate(-520px, 200px);
  }
  .info__v {
    transform: scale(0.3) translate(520px, -400px);
  }
  .name__b {
    transform: translate(-380px, -420px);
  }
  .btn__info_b {
    transform: translate(-270px, -420px);
  }
  .biography__ {
    padding-top: 6.8em ;
  }
}

@media only screen and (max-width: 419px) {
  .biography__ {
    padding-top: 6.8em;
  }
}

@media only screen and (max-width: 320px) {
   .biography__ {
    padding-top: 5em;
  }
}
</style>
