<template>
  <div id="seyvom__">
    <Navbar />
    <Background />
    <a id="scrollToTopBtn"><i class="fa fa-arrow-up" aria-hidden="true"></i></a>
    <a id="scrollDownBtn">
      <i class="fa fa-angle-down scrollDownArrow" aria-hidden="true"></i>scroll
      down</a
    >
    <div class="scroll_container" id="scroll_container">
      <div id="Home" class="panel--"><Index /></div>
      <div id="Biography" class="panel--">
        <span class="top"><Biography /></span>
      </div>
      <div id="Projects" class="panel--">
        <span class="top"><Projects /></span>
      </div>
      <div id="Contacts" class="panel--">
        <span class="top"><Contacts /></span>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar/Navbar.vue";
import Background from "@/components/shared/background.vue";
import Index from "@/components/Sections/SectionHome.vue";
import Biography from "@/components/Sections/SectionBiography.vue";
import Projects from "@/components/Sections/SectionProjects.vue";
import Contacts from "@/components/Sections/SectionContacts.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Background,
    Index,
    Biography,
    Projects,
    Contacts,
  },
  mounted() {
      require("@/assets/js/scroll.js");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#seyvom__ {
  overflow:hidden;
}
.active {
  opacity: 1;
  animation-name: fadein;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.45s;
}
.deactivate {
  opacity: 0;
  animation-name: fadeout;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.45s;
}
#scrollToTopBtn {
  position: fixed;
  bottom: 60px;
  right: 30px;
  background-color: rgb(198, 67, 66, 0.6);
  z-index: 1;
  text-align: center;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 24px;
  line-height: 50px;
  width: 52px;
  display: none;
}
#scrollDownBtn {
  display: none;
  z-index: 1;
  text-align: center;
  right: 40%;
  left: 41%;
  position: fixed;
  bottom: 60px;
  fill: #212121;
  font-size: 24px;
  font-family: Montserrat-Light, Montserrat;
  font-weight: 300;
}
.scrollDownArrow {
  position: absolute;
  transform: translate(55px, 31px);
  animation: arrow-down-bounce 2s infinite;
}
@media only screen and (max-width: 1450px) {
  #scrollDownBtn {
    font-size: 18px;
    bottom: 50px;
  }
  .scrollDownArrow {
    transform: translate(44px, 25px);
    animation: arrow-down-bounce-1450 2s infinite;
  }
  /*-- bounce 1450px--*/
  @keyframes arrow-down-bounce-1450 {
    0% {
      transform: translateX(44px) translateY(25);
    }
    30% {
      transform: translateX(44px) translateY(30px);
    }
    50% {
      transform: translateX(44px) translateY(25);
    }
    100% {
      transform: translateX(44px) translateY(25);
    }
  }
}
@media only screen and (max-width: 992px) {
  #scrollDownBtn {
    font-size: 16px;
    bottom: 45px;
  }
  .scrollDownArrow {
    transform: translate(39px, 22px);
    animation: arrow-down-bounce-992 2s infinite;
  }
  /*-- bounce 992px --*/
  @keyframes arrow-down-bounce-992 {
    0% {
      transform: translateX(39px) translateY(22);
    }
    30% {
      transform: translateX(39px) translateY(28px);
    }
    50% {
      transform: translateX(39px) translateY(22);
    }
    100% {
      transform: translateX(44px) translateY(25);
    }
  }
}
@media only screen and (max-width: 499px) {
  #scrollToTopBtn {
    bottom: 40px;
    right: 15px;
    font-size: 22px;
    line-height: 45px;
    width: 46px;
  }
  #scrollDownBtn {
    bottom: 35px;
    right: unset;
    left: 37.58vw;
  }
}

@media only screen and (max-width: 400px) {
}
</style>
