<template>
  <section class="contacts__">
    <div class="row">
      <div class="box__socialmedia col-md-7">
        <div class="contacts__socialmedia">
          <div class="--contacts__info">
            <div data-depth="0.2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="367"
                height="288"
                viewBox="0 0 367 288"
              >
                <g transform="translate(-1227 -239)">
                  <line
                    style="fill: none;
                stroke: #212121;
                stroke-linecap: round;
                stroke-width: 5px;"
                    x2="179"
                    transform="translate(1229.5 345.5)"
                  />
                  <line
                    style="fill: none;
                stroke: #212121;
                stroke-linecap: round;
                stroke-width: 5px;"
                    x2="224"
                    transform="translate(1363.5 364.5)"
                  />
                  <text
                    style="fill: #212121;
                font-size: 72px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: 700;"
                    transform="translate(1230 309)"
                  >
                    <tspan x="0" y="0">CONTACT</tspan>
                  </text>
                  <text
                    style="fill: #212121;
                font-size: 56px;
                font-family: Montserrat-Light, Montserrat;
                font-weight: 300;"
                    transform="translate(1230 445)"
                  >
                    <tspan x="0" y="0">Get in touch</tspan>
                    <tspan id="contact__text_with_us_high" x="0" y="68">
                      with us!
                    </tspan>
                    <tspan
                      id="contact__text_with_us_low"
                      style="display:none"
                      x="68"
                      y="68"
                    >
                      with us!
                    </tspan>
                  </text>
                </g>
              </svg>
            </div>
          </div>
          <div class="contact__email">
            <a href="mailto:info@seyvom.com">
              <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
              <span style="font-weight:500;"> info@seyvom.com</span></a
            >
          </div>
          <div class="contact__social">
            <div class="contact__insta">
              <a href="https://www.instagram.com/seyvom/" target="_blank"
                ><span>
                  <i class="fa fa-instagram" aria-hidden="true"></i
                ></span>
                <span style="font-weight:500;"></span
              ></a>
            </div>
            <div class="contact__fb">
              <a href="https://www.fb.com/seyvom/" target="_blank"
                ><span
                  ><i class="fa fa-facebook-square" aria-hidden="true"></i
                ></span>
                <span style="font-weight:500;"></span
              ></a>
            </div>
            <div class="contact__linkedin">
              <a href="https://www.linkedin.com/company/seyvom" target="_blank"
                ><span
                  ><i class="fa fa-linkedin-square" aria-hidden="true"></i
                ></span>
                <span style="font-weight:500;"></span
              ></a>
            </div>
          </div>
          <div class="contact_lets__talk">
            <router-link class="btn btn-contact_lets__talk" to="/lets-talk"
              >Let’s talk!</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="contact__we">
          <div class="--contact__we_1">
            <div data-depth="0.8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;"
                width="651.119"
                height="627.371"
                viewBox="0 0 651.119 627.371"
              >
                <path
                  style="fill: none;
                  stroke: #c2363b;
                  stroke-dasharray: 20 50;
                  opacity: 0.5;"
                  d="M1274,5953.844s534.355,155.464,573.45,41.623-18.344-235.068-92.257-276.715-537.785-154.93-494.648-77.789,597.594,4.043,642.635-41.036-250.525-216.718-355.449-185.256"
                  transform="translate(-1257.219 -5410.271)"
                />
              </svg>
            </div>
          </div>
          <div class="--contact__we_2">
            <div data-depth="0.4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(-220px, 150px);"
                width="733.414"
                height="425.141"
                viewBox="0 0 733.414 425.141"
              >
                <path
                  style="fill: none;
                  stroke: #ffbf51;
                  stroke-dasharray: 20 40;
                  opacity: 0.5;"
                  d="M1719.271,5562.039s-52.4,117.668-179.188,109.564-405.435,18.252-365.615,147.025S986.4,5986.483,986.4,5986.483"
                  transform="translate(-986.314 -5561.835)"
                />
              </svg>
            </div>
          </div>
          <div class="--contact__we_3">
            <div data-depth="0.3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(130px, 280px);"
                width="150.667"
                height="280.965"
                viewBox="0 0 150.667 280.965"
              >
                <path
                  style="fill: #262626;"
                  d="M429.756,1864.72s-20.052,3.67-25.773,13.611,28.008,4.824,29.212,2.71a50.456,50.456,0,0,1,4.986-6.16S435.654,1865.266,429.756,1864.72Z"
                  transform="translate(-390.11 -1610.011)"
                />
                <path
                  style="fill: #262626;"
                  d="M893.1,1888.638s15.775,11.34,26.618,11.34,11.444-.3,11.142-3.312-11.084-16-26.623-20.346S893.1,1888.638,893.1,1888.638Z"
                  transform="translate(-795.022 -1619.013)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M635.631,565.059a28.47,28.47,0,0,1-10.1-15.191c-.476-1.807-1.63-2.24-2.982-2.776.162-.689,2.555-.507.722-2.042a2.393,2.393,0,0,1-.689-2.615c1.239-2.886.534-5.918.619-8.88a11.21,11.21,0,0,1,2.035-6.892,16.817,16.817,0,0,0,2.8-7.591c.507-2.891,2.164-4.245,5.024-4.307a7.114,7.114,0,0,0,2.426-.215c3.791-1.489,7.007-.908,9.549,2.412,3.349.689,6.449-.563,9.576-1.332,2.646-.651,4.207.172,4.959,2.719a33.1,33.1,0,0,0,4.633,9.476c2.1,3.046,1.315,6.6,1.191,9.938-.065,1.785.744.953,1.437.894,2.343,2.767-.184,5.686.267,8.5-1.856.622-2.024,2.393-2.521,3.87-1.689,4.962-4.073,9.533-8.391,12.617-2.221,1.585-1.261,3.6-1.664,5.451-1.769-1.621-3.587-1.745-6-1.147C643.9,569.1,639.358,568.548,635.631,565.059Z"
                  transform="translate(-571.469 -491.826)"
                />
                <path
                  style="fill: #1c1c1c;"
                  d="M618.956,412.815c-1.068.159-2.312-.345-2.386-1.142-.358-3.79-2.6-1.933-4.559-1.942-6.137-.022-7.981,3.263-10.434,8.694-2.572,5.7-3.036,10.882-2.9,16.613.028,1.182.293,2.652-1.75,2.3-2.018-2.676-1.678-6.008-2.5-9.018,1.011-.924,2-1.806,1.421-3.446-1.108-3.174-.479-6.485-.629-9.735-.152-3.3.641-6.657-2.8-9.192-1.856-1.368-1.47-3.548.689-5.112,2.05-1.49,4.48-2.2,6.94-3.274-1.4-1.632-3.158-.765-4.4-1.678-1.008-.744-2.228-1.234-1.833-2.779.329-1.287,1.425-1.466,2.567-1.551,3.5-.269,6.947.4,10.531.889-.159-.617-.15-.925-.3-1.1-1.132-1.378-4.442-1.259-3.658-3.644.7-2.126,3.415-1.063,5.14-.944,8.8.608,17.593,1.418,24.831,7.372a4.314,4.314,0,0,1,.221,4.281C630.5,405.253,626.549,410.837,618.956,412.815Z"
                  transform="translate(-545.641 -386.38)"
                />
                <path
                  style="fill: #feeaec;"
                  d="M612.693,896.93a3.822,3.822,0,0,0,2.791.145c2.865,4.145,5.765,8.1,10.622,10.365,10.653,4.971,22.669,1.464,28.658-8.709a11.9,11.9,0,0,1,1.125-1.7c.992.026,1.061,1.11,1.723,1.513,1.39,1.08,1.192,2.653,1.216,4.121.055,3.4-.153,6.811.062,10.2.251,3.949-1.58,6.2-5.169,7.174-11.924,3.234-27.395,51.294-39.311,54.569-1.966.539-.415-42.445-2.24-44.55-1.108-2.617-.689-5.377-.706-8.081q-.083-9.821,0-19.624C611.48,900.479,611.22,898.493,612.693,896.93Z"
                  transform="translate(-562.332 -808.965)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M674.965,853.022a2.515,2.515,0,0,1-.431,2.662c-4.636,7.422-11.416,11.168-20.065,11.01-8.442-.155-15.1-3.884-19.469-11.325a2.724,2.724,0,0,1-.446-2.307c5.081-.386,8.973-2.331,10.179-7.8,4.7,1.077,9.132-.775,13.687-1.287,2.333-.264,4.617-.706,6.328,1.5C666.374,850.414,669.863,852.812,674.965,853.022Z"
                  transform="translate(-581.407 -764.952)"
                />
                <path
                  style="fill: #8d2b26;"
                  d="M842.588,965.569c-1.284-1.6-.482-3.044.289-4.523,4.216-8.077,8.6-16.078,10.836-25.027.272-1.091.436-2.545,2.173-2.457,11.22,6.659,20.908,5.884,24.747,34.411,0,0,5.715,25.8,2.2,53.756-2.929,23.273-14.979,49.574-16.809,49.634-8.063.269-28.37-34.4-32.133-35.208s3.42-13.256,8.709-70.589"
                  transform="translate(-745.616 -839.283)"
                />
                <path
                  style="fill: #8d2b26;"
                  d="M446.54,960.192c-4.294-7.557-8.118-15.295-10.095-23.835-.224-.973-.243-2.291-1.852-2.131-3.823,2.309-13.391,9.807-16.728,21.8-6.256,22.474-4.988,55.808-4.042,65.725,1.625,17.057,14.945,19.642,16.137,19.616l15.162,28.2s70.255,2.352,73.416,2.352S448.75,967.394,448.454,966.257,446.54,960.192,446.54,960.192Z"
                  transform="translate(-398.143 -839.824)"
                />
                <path
                  style="fill: #edd3d6;"
                  d="M616.893,935.332c10.327-1.683,20.251-10.755,30.286-13.465q5.675-1.532,11.3-3.239c2.436-.737,3.73-2.136,3.558-5.043-.3-5.017-.141-10.062-.172-15.095l.861-1.63c1.533,1.5,1.378,3.463,1.378,5.341.048,8.4.01,16.8.026,25.193,0,2.731,4.164,76.071,3.825,78.738-15.32,0-32.882,1.666-48.2,1.657C619.647,1006.141,615.274,936.874,616.893,935.332Z"
                  transform="translate(-566.58 -808.907)"
                />
                <path
                  style="fill: #ea5546;"
                  d="M552.357,925.636q-.15,2.454-.3,4.907a1.084,1.084,0,0,1-.648-.124c.072-1.881-.955-3.387-1.819-4.888-4.8-8.329-9.228-16.8-10.854-26.432-.038-.221-.345-.393-.534-.588,3.98-7.185,7.422-8.775,13.911-6.428Z"
                  transform="translate(-501.743 -804.119)"
                />
                <path
                  style="fill: #ea5546;"
                  d="M885.908,925.485c-.047-12.831-1.271-20.315-1.318-33.146,5.718-2.08,9.428-.762,12.622,4.442a18.126,18.126,0,0,0,1.5,1.885,143.616,143.616,0,0,1-10.97,26.094c-1,1.864-1.694,2.145-1.278,4.581C886.247,929.448,886.153,925.485,885.908,925.485Z"
                  transform="translate(-788.452 -804.386)"
                />
                <path
                  style="fill: #262626;"
                  d="M752.12,449.979c6.292-3.6,11.471-8.175,13.463-15.506a31.111,31.111,0,0,0,.517-3.193c4.611,5.617,10.575,10.469,9.152,19.125-.613,3.732.065,7.67-.495,11.418-.32,2.154.517,2.858,1.964,3.646-1.218,1.456-1.27,3.529-2.6,4.926-1.142,1.11-.038,3.031-2.312,4.709,0-3.832-.1-7.064.034-10.3a7.308,7.308,0,0,0-1.98-5.858c-2.219-2.4-3.274-5.579-3.987-8.806-.414-1.88-1.182-2.676-3.417-1.878C759.163,449.441,755.736,450.449,752.12,449.979Z"
                  transform="translate(-678.806 -423.544)"
                />
                <path
                  style="fill: #a57367;"
                  d="M714.2,818.721c-5.474-1.942-10.548.991-15.836,1.347-1.747.117-3.547,1.051-4.18-1.561q.417-3.534.83-7.078c5.351,2.093,10.663,3.373,16.368.948,3.237-1.378.708,2.814,2.516,3.1Q714.044,817.1,714.2,818.721Z"
                  transform="translate(-630.848 -738.197)"
                />
                <path
                  style="fill: #a57367;"
                  d="M874.892,634.66c.62-1.771-1.509-5,2.6-4.926,4.364,3.892,3.231,10.544-2.283,13.4-2.007-.3-1.263-1.7-.882-2.66A12.25,12.25,0,0,0,874.892,634.66Z"
                  transform="translate(-779.577 -587.805)"
                />
                <path
                  style="fill: #a57367;"
                  d="M598.24,629.8c2.44,0,1.571,1.937,1.778,3.156.329,1.938.489,3.906.72,5.858.481,1.463,4.521,3.1-.212,4.307C595.308,639.9,594.3,634.023,598.24,629.8Z"
                  transform="translate(-549.45 -587.86)"
                />
                <path
                  style="fill: #8d3937;"
                  d="M735.5,738.78c.336,1.151-2.841.917-6.2.963-4.39.047-7.64-.665-6.942-.963Z"
                  transform="translate(-654.091 -678.064)"
                />
                <path
                  style="fill: #50242b;"
                  d="M659.1,614.815c2.054-1.632,14.3-4.545,12.85-.782C667.723,613.022,664.016,614.028,659.1,614.815Z"
                  transform="translate(-601.812 -573.287)"
                />
                <path
                  style="fill: #8d3937;"
                  d="M766.6,631.13c2.2,5.119,2.045,10.8-1.442,13.556C768.911,639.652,764.774,635.427,766.6,631.13Z"
                  transform="translate(-689.599 -588.961)"
                />
                <path
                  style="fill: #4a242a;"
                  d="M802.042,603.629c-3.413-1.316-3.915-2.7-7.622-.253C796.6,598.088,798.374,598.341,802.042,603.629Z"
                  transform="translate(-713.818 -562.809)"
                />
                <path
                  style="fill: #262626;"
                  d="M726.567,722.445c-4.507.212-13.279.071-18.027.284C716.534,718.861,721.577,720.6,726.567,722.445Z"
                  transform="translate(-642.734 -662.858)"
                />
                <path
                  style="fill: #262626;"
                  d="M645.43,736.9s.948,10.327,20.184,18.666a35.02,35.02,0,0,0,9.759-6.444c1.611-1.327,7.343-10.045,7.912-13.075-4.824,5.4-7.817,11.844-17.765,11.844-7.3,0-10.248-1.833-13.575-3.529C648.865,742.8,649.758,742.644,645.43,736.9Z"
                  transform="translate(-590.498 -675.804)"
                />
                <path
                  style="fill: #262626;"
                  d="M746.08,758.108s1.657,3.317,3.174,3.98c1.706-1.232,2.464-4.028,2.464-4.028S746.221,758.818,746.08,758.108Z"
                  transform="translate(-673.806 -694.022)"
                />
                <path
                  style="fill: #234f82;"
                  d="M557.76,1378.71h-95.52L448.869,1331.5a4.2,4.2,0,0,1,4.039-5.341H565.6a4.2,4.2,0,0,1,4.071,5.224Z"
                  transform="translate(-427.672 -1164.242)"
                />
                <path
                  style="fill: #353535;"
                  d="M435.819,1577.906s-80.137-73.554-96.724-61.509-13.322,19.869-9,22.672,94.589,51.147,94.589,51.147Z"
                  transform="translate(-326.601 -1320.599)"
                />
                <path
                  style="fill: #525354;"
                  d="M565.135,1602.627s104.321-31.358,102.573-51.783-22.62-14.888-27.253-12.636-83.745,54.26-83.745,54.26Z"
                  transform="translate(-517.064 -1337.759)"
                />
                <path
                  style="fill: #feeaec;"
                  d="M755.11,1441.156c0,5.1-6.3,10.906-11.489,10.906s-10.761-5.513-10.761-10.617a9.445,9.445,0,0,1,9.156-9.445C742.746,1444.061,743.982,1442.027,755.11,1441.156Z"
                  transform="translate(-662.864 -1251.846)"
                />
                <path
                  style="fill: #6d2e30;"
                  d="M697.441,653.664c0,1.206-.689,1.723-1.415,1.723s-1.216-.517-1.216-1.723.589-2.174,1.315-2.174S697.441,652.464,697.441,653.664Z"
                  transform="translate(-631.37 -605.813)"
                />
                <path
                  style="fill: #6d2e30;"
                  d="M811.659,651.9c0,1.206-.517,2.176-1.154,2.176s-1.154-.973-1.154-2.176.558-1.563,1.2-1.563S811.659,650.7,811.659,651.9Z"
                  transform="translate(-726.175 -604.861)"
                />
              </svg>
            </div>
          </div>
          <div class="--contact__we_4">
            <div data-depth="0.4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(300px, 300px);"
                width="272.375"
                height="267.542"
                viewBox="0 0 272.375 267.542"
              >
                <path
                  style="fill: #c67a60;"
                  d="M893.963,908.417,890,909.892l4.071,14.138,34.133,5.3,1.9-19.719-10.064-2.855-22.2.872A12.654,12.654,0,0,0,893.963,908.417Z"
                  transform="translate(-777.311 -826.757)"
                />
                <path
                  style="fill: #262626;"
                  d="M864.7,518.018c-4.864-9.044-7.97-13.043-9.557-20.675a10.211,10.211,0,0,1-1.6-4.633,88.7,88.7,0,0,1-.722-17.82c-.389-4.788-2.814-3.57-3.243-8.355-.653-7.259,11.232-15.207,15.7-20.98,25.477-10.456,31.209,7.695,38.992,18.263-.488,4.135-1.389,8.22-2.1,12.329-.205,6.078-1.378,12.043-2.1,18.062-.143,1.19-.944,2.05-1.378,3.089-.822,3.417-1.54,4.035-2.548,7.4-1.513,5.055-4.611,7.378-9.109,14.555a2.961,2.961,0,0,1-1.551,1.464c-6.818,2.307-13.463,2.178-19.828-1.5A2.146,2.146,0,0,1,864.7,518.018Z"
                  transform="translate(-743.83 -442.417)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M748.548,934.39c7.672,3.458,15.417,6.785,22.989,10.429,4.168,2.005,5.267,6.039,6.189,9.993.017,1.862,13.492,36.237,13.691,52.987.258,22.054-12.731,12.159-11.235,22.929-40.856,29.137-41.316,69.359-87.87,9.636-3.987-5.115-10.165-7.374-14.161-10.87-4.378-3.823-7.934-7.186-10.779-9.083-2.24-1.49,3.759-61.681,4.163-64.479.977-5.93,3.558-10.479,10.086-12.882,6.356-2.34,12.345-5.558,18.5-8.392,1.783.567,2.274,2.185,3.168,3.461,3.377,4.824,7.409,9.018,13.379,10.987,11.173,3.692,22.862,1.334,29.221-10.524C746.667,937.143,746.866,935.343,748.548,934.39Z"
                  transform="translate(-592.627 -849.626)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M866.047,922.643c.517-.345,1.034-.689,1.523-1.053,2.005-1.511,3.086-1.309,4.256,1.27,3.5,7.727,9.476,12.081,18.234,11.788,7.569-.253,12.708-4.19,15.851-10.917,1.723-3.7,1.67-3.728,5.236-1.842.455.24.874.55,1.308.829-2.36,8.292-6.961,14.845-15.558,17.161s-16.652.667-23.415-5.619a32.949,32.949,0,0,1-6.892-8.8C866.133,924.59,865.23,923.775,866.047,922.643Z"
                  transform="translate(-757.212 -838.246)"
                />
                <path
                  style="fill: #a57367;"
                  d="M937.292,881.21c7.236,4.047,14.714,4.014,22.336,1.235.079,1.551.172,2.793.172,4.652.012,5.558-6.4,12.176-12.233,11.9-3.1-.148-10.682-5.2-10.3-10.877C937.449,885.666,937.28,884.523,937.292,881.21Z"
                  transform="translate(-816.426 -805.609)"
                />
                <path
                  style="fill: #a57367;"
                  d="M845,630.88c1.18,5.786.713,11.733,1.723,17.54.283,1.625.4,3.274.6,4.916-6.506-3.081-7.014-8.025-7.064-15.033-.019-3.1-.984-5.031,2.169-4.976C844.436,633.361,844.963,632.563,845,630.88Z"
                  transform="translate(-736.007 -598.409)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1134.75,659.382c1.378-7.017.951-14.328,3.475-21.152.146.274.226.689.448.8,4.86,2.2,3.951,3.8,2.445,8.985C1139.757,652.694,1138.9,656.8,1134.75,659.382Z"
                  transform="translate(-979.892 -604.493)"
                />
                <path
                  style="fill: #065c70;"
                  d="M861.215,932.26c5.858,16.049,24.293,23.132,37.732,13.215,4.008-2.958,5.929-7.44,7.988-11.778.217-.458.457-.908.689-1.361l.689.289c-2.929,8.2-7.317,15.134-16.39,17.376-8.692,2.148-16.618.481-23.46-5.634a42.4,42.4,0,0,1-8.584-11.447A1.512,1.512,0,0,1,861.215,932.26Z"
                  transform="translate(-752.38 -847.863)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M923.179,589.214c-2.829,3.026.029-4.269-1.537-7.124a5.787,5.787,0,0,0-7.478-2.757,6.437,6.437,0,0,1-4.45.04c-8.539-2.81-5.2,8.787-8.53,10.458-2.6,1.306-3.92-7.436-5.009-8.982-1.046-1.666-1.973-8.684-2.662-10.1-3.187-6.807-.937-16.445,1.378-23.27,6.737-.663,11.64.41,16.788-2.541,3.9-2.24,8.143-2.514,12.591-2.54,2.71-.017,3.577,4.493,4.623,6.892,3.124,7.154,4.984,14.442,1.435,22.054C929.714,572.953,928.005,584.052,923.179,589.214Z"
                  transform="translate(-778.912 -525.173)"
                />
                <path
                  style="fill: #444;"
                  d="M971.25,782c4.928,1.551,10.112.555,14.268.345C982.418,785.236,974.975,785.742,971.25,782Z"
                  transform="translate(-844.562 -723.492)"
                />
                <path
                  style="fill: #444;"
                  d="M1001.758,805.267c-2.567,1.993-4.363,2.292-6.518.558C997.247,804.726,999.1,805.27,1001.758,805.267Z"
                  transform="translate(-864.418 -742.641)"
                />
                <path
                  style="fill: #3f2030;"
                  d="M1047.147,630.95a10.319,10.319,0,0,1,6.03,1.254c.637.4,1.142.944.853,1.813-.308.923-1.192-.1-2.13-.291-1.735-.353-3.23.689-5.026.724a1.582,1.582,0,0,1-1.806-1.7C1045.085,631.444,1045.951,630.958,1047.147,630.95Z"
                  transform="translate(-905.662 -598.458)"
                />
                <path
                  style="fill: #402031;"
                  d="M930.75,633.221c-.186.184-.5.782-.894.842a31.039,31.039,0,0,1-6.337-.052c-1.328-.05-2.229-.543-1.215-1.773,1.1-1.328,6.346-2.1,7.722-1.168A1.8,1.8,0,0,1,930.75,633.221Z"
                  transform="translate(-803.688 -598.228)"
                />
                <path
                  style="fill: #7a3633;"
                  d="M997.573,687c1.625,2.6.064,4.437.183,6.33s-.412,4.135,2.853,3.36c-.639,2.424-2.03,1.573-3.229,1.235-1.47-.412-1.466-1.378-1.151-2.693s.145-2.691.345-4.021C996.739,690,997.126,688.821,997.573,687Z"
                  transform="translate(-865.097 -644.86)"
                />
                <path
                  style="fill: #4c2833;"
                  d="M943.658,682.529c-.172,1.156-.77.493-2.078.517-1.57.033-3.586,1.306-3.634.086-.047-1.16,1.928-1.358,3.2-1.378C942.693,681.717,943.413,681.393,943.658,682.529Z"
                  transform="translate(-816.995 -640.459)"
                />
                <path
                  style="fill: #4e2934;"
                  d="M1055.262,681.389c-.376,1.178-1.923.463-3.547.345-1.282-.093-1.757.744-1.723-.4.028-1.24.257-.717,1.816-.634C1053.093,680.771,1054.974,680.345,1055.262,681.389Z"
                  transform="translate(-909.735 -639.492)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1139.49,1875.286s16.775,11.585,27.848,8.6-16.4-23.211-18.74-22.57a50.757,50.757,0,0,1-7.881.83S1135.706,1870.727,1139.49,1875.286Z"
                  transform="translate(-982.645 -1616.838)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M688.956,1836.1s-20.052,3.67-25.773,13.611,28.006,4.824,29.212,2.71a50.672,50.672,0,0,1,4.984-6.159S694.854,1836.646,688.956,1836.1Z"
                  transform="translate(-589.032 -1595.977)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M1600.875,1194.279s-6.2,12.507,6.328,12.715,26.533-10.784,28.532-13.783.6-10.393.6-10.393-.8-4.2-4.6-2-14.817,4.859-18.818,7.064S1600.591,1195.742,1600.875,1194.279Z"
                  transform="translate(-1364.436 -1053.07)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1622.55,1197.777s3.35-2.619-.8-3.863-8.1-4.771-12.031-.622-4.978,12.03-4.978,13.267-.415,10.786,2.7,8.5,6.015-12.652,5.808-15.763C1622.076,1198.52,1611.971,1200.265,1622.55,1197.777Z"
                  transform="translate(-1368.891 -1062.297)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M268.934,1144.479s5.7,12.507-5.811,12.715-24.354-10.784-26.189-13.784-.551-10.393-.551-10.393.734-4.2,4.219-2,12.405,4.859,16.068,7.064S269.193,1145.944,268.934,1144.479Z"
                  transform="translate(-235.946 -1011.85)"
                />
                <path
                  style="fill: #a57367;"
                  d="M333.146,1150.647s-3.075-2.619.734-3.863,8.63-4.771,12.248-.622c-1.034-1.177,4.569,16.132,4.569,13.267,0,1.246.381,10.787-2.476,8.5s-6.334-5.872-6.142-8.983C342.41,1152.041,342.854,1153.135,333.146,1150.647Z"
                  transform="translate(-315.428 -1023.287)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M1289.327,1277.76s2.943,8.572,8.749,9.4c-15.572,6.511-49.249,25.058-60.208,29.366S1196.986,1301.55,1289.327,1277.76Z"
                  transform="translate(-1054.109 -1133.835)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M405.4,1231.282s5.562-7.7,4.752-11.182c14.657,7.455,46.4,21.27,56.568,26.661S484.271,1283.3,405.4,1231.282Z"
                  transform="translate(-376.204 -1086.11)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M1207.784,1161.98l-.684,49.958,7.14-.146Z"
                  transform="translate(-1039.776 -1038.004)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M808.2,1139.58l-.684,49.958,7.14-.146Z"
                  transform="translate(-709.041 -1019.463)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M1360.247,1362.37l-47.477,15.56,2.453,6.706Z"
                  transform="translate(-1127.24 -1203.868)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M470.41,1328l42.388,26.447,3.572-6.184Z"
                  transform="translate(-430.013 -1175.419)"
                />
                <path
                  style="fill: #c9c9c9;"
                  d="M767.331,1391.97h-95.52l-13.372-47.208a4.2,4.2,0,0,1,4.039-5.341H775.168a4.2,4.2,0,0,1,4.071,5.224Z"
                  transform="translate(-585.515 -1184.872)"
                />
                <path
                  style="fill: #feeaec;"
                  d="M964.66,1454.391c0,5.1-6.3,10.906-11.489,10.906s-10.761-5.513-10.761-10.617a9.457,9.457,0,0,1,9.162-9.45C952.3,1457.3,953.538,1455.263,964.66,1454.391Z"
                  transform="translate(-820.691 -1272.451)"
                />
                <path
                  style="fill: #1d4368;"
                  d="M621.039,1568.8s-87.76-71.929-106.615-59.393-15.4,20.312-10.637,23.02,104.486,49.036,104.486,49.036Z"
                  transform="translate(-454.532 -1324.353)"
                />
                <path
                  style="fill: #234f82;"
                  d="M808.118,1577.025s111.13-31.492,109.033-52.77-24.331-15.741-29.257-13.439S799,1566.361,799,1566.361Z"
                  transform="translate(-701.989 -1324.757)"
                />
                <path
                  style="fill: #717272;"
                  d="M817.354,621.525a9.266,9.266,0,0,0-4.667.134c-3.02.965-6.092,4.19-4.359,13.9,3.084,17.25,11.273,11.182,11.273,11.182S820.491,638.812,817.354,621.525Z"
                  transform="translate(-709.294 -590.469)"
                />
                <path
                  style="fill: #717272;"
                  d="M1137.16,650.03s11.239,5.539,11.6-11.983-8.859-13.17-8.859-13.17S1139.122,634.854,1137.16,650.03Z"
                  transform="translate(-981.886 -593.043)"
                />
                <path
                  style="fill: #444;"
                  d="M890.549,453.464c-3.031-3.618-18.17-5.005-25.715-4.5-3.908.264-15.112,2.359-21.02,12.031-.861,1.42-2.35,14.09-3.044,18.537,1-.8,2.6-.345,3.856-.724.012-.2-.846-7.689-.812-7.874,1.918-10.165,11.442-16.5,13.983-18.06s10.51-.877,20.675-.388,10.908,6.938,11.983,7.926,4.5,17.5,4.5,17.5-2.343,4.3,2.057,3.418C897.007,478.881,893.58,457.081,890.549,453.464Z"
                  transform="translate(-736.563 -447.754)"
                />
              </svg>
            </div>
          </div>
          <div class="--contact__we_5">
            <div data-depth="0.7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(-110px, 458px);"
                width="155.537"
                height="85.039"
                viewBox="0 0 155.537 85.039"
              >
                <g transform="translate(0 0.002)">
                  <path
                    style="fill: #f7d194;"
                    d="M121.441,85.079,0,.05,114.494,57.3l6.315,25.677"
                    transform="translate(0 -0.042)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M0,.05,85.029,75.82,114.494,57.3Z"
                    transform="translate(0 -0.042)"
                  />
                  <path
                    style="fill: #ba8a43;"
                    d="M0,.008c3.364-.841,128.385,53.46,128.385,53.46l-6.944,31.569-6.946-27.781Z"
                    transform="translate(0 0)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M128.387,53.51l27.15-16.417L0,.05Z"
                    transform="translate(0 -0.042)"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="--contact__we_6">
            <div data-depth="0.3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(483px, 60px);"
                width="153.98"
                height="85.039"
                viewBox="0 0 153.98 85.039"
              >
                <g transform="translate(0 0.002)">
                  <path
                    style="fill: #f7d194;"
                    d="M0,85.079,120.225.05,6.877,57.3.625,82.975"
                    transform="translate(33.755 -0.042)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M113.348.05,29.17,75.82,0,57.3Z"
                    transform="translate(40.631 -0.042)"
                  />
                  <path
                    style="fill: #ba8a43;"
                    d="M127.1.008C123.77-.833,0,53.468,0,53.468L6.875,85.037l6.877-27.781Z"
                    transform="translate(26.88 0)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M26.878,53.51,0,37.093,153.98.05Z"
                    transform="translate(0 -0.042)"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="--contact__we_7">
            <div data-depth="0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(50px, 358px);"
                width="65.707"
                height="60.052"
                viewBox="0 0 65.707 60.052"
              >
                <g transform="matrix(0.819, 0.574, -0.574, 0.819, 19.293, 0)">
                  <rect style="fill: #ba8a43;" width="56.661" height="33.636" />
                  <path
                    style="fill: #f7d194;"
                    d="M0,224.592,29.939,201.03l26.722,23.562Z"
                    transform="translate(0 -190.956)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M0,0S27.118,19.47,29.939,19.47,56.661,0,56.661,0Z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="--contact__we_8">
            <div data-depth="0.6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="position: absolute;transform: translate(380px, 160px);"
                width="65.888"
                height="57.46"
                viewBox="0 0 65.888 57.46"
              >
                <g transform="translate(0 28.331) rotate(-30)">
                  <rect style="fill: #ba8a43;" width="56.661" height="33.636" />
                  <path
                    style="fill: #f7d194;"
                    d="M0,224.592,29.939,201.03l26.722,23.562Z"
                    transform="translate(0 -190.956)"
                  />
                  <path
                    style="fill: #ffc65a;"
                    d="M0,0S27.118,19.47,29.939,19.47,56.661,0,56.661,0Z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Contacts",
  mounted() {
    new this.$parallax(document.querySelector(".--contacts__info"));
    new this.$parallax(document.querySelector(".--contact__we_1"));
    new this.$parallax(document.querySelector(".--contact__we_2"));
    new this.$parallax(document.querySelector(".--contact__we_3"));
    new this.$parallax(document.querySelector(".--contact__we_4"));
    new this.$parallax(document.querySelector(".--contact__we_5"));
    new this.$parallax(document.querySelector(".--contact__we_6"));
    new this.$parallax(document.querySelector(".--contact__we_7"));
    new this.$parallax(document.querySelector(".--contact__we_8"));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contacts__ {
  padding-top: 14em;
}
.contacts__socialmedia {
  padding-left: 10em;
}
.contact__we {
  transform: scale(0.9);
}
.contact__email {
  padding-top: 1.5em;
}
.contact__social {
  padding-top: 1em;
  display: inline-flex;
}
.contact__email,
.contact__insta,
.contact__fb,
.contact__linkedin {
  color: #212121;
  font-size: 24px;
  font-family: Montserrat-Regular, Montserrat;
}
.contact__fb {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.contact__email a {
  text-decoration: unset;
  color: #242424;
}
.contact__insta a,
.contact__fb a,
.contact__linkedin a {
  text-decoration: unset;
  color: #242424;
  font-size: 35px;
}
.contact__insta a :hover {
  color: #e1306c;
  transition: all 0.2s ease-in-out;
}
.contact__fb a :hover {
  color: #4267b2;
  transition: all 0.2s ease-in-out;
}
.contact__linkedin a :hover {
  color: #2867b2;
  transition: all 0.2s ease-in-out;
}
.contact_lets__talk {
  padding-top: 2em;
}
.btn-contact_lets__talk {
  color: #212121 !important;
  border-radius: 7px;
  padding: 9px;
  width: 150px;
  text-align: center;
  font-size: 18px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  box-shadow: 5px 10px;
  background-color: #ffce00;
  -webkit-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  animation: jittery 3s infinite;
}

@media only screen and (max-width: 1635px) {
  .contact__we {
    transform: scale(0.9) translate(-100px, 10px);
  }
}
@media only screen and (max-width: 1515px) {
  .contact__we {
    transform: scale(0.8) translate(-100px, 10px);
  }
  .contacts__socialmedia {
    padding-left: 8em;
    transform: scale(0.9) translate(0px, -30px);
  }
}
@media only screen and (max-width: 1375px) {
  .contact__we {
    transform: scale(0.7) translate(-180px, 10px);
  }
  .contacts__socialmedia {
    padding-left: 4em;
    transform: scale(0.8) translate(0px, -65px);
  }
}
@media only screen and (max-width: 1200px) {
  .contact__we {
    transform: scale(0.6) translate(-200px, 10px);
  }
  .contacts__socialmedia {
    padding-left: 0em;
    transform: scale(0.7) translate(0px, -110px);
  }
  .btn-contact_lets__talk {
    font-size: 20px;
  }
  .contacts__ {
    padding-top: 12em;
  }
}
@media only screen and (max-width: 992px) {
  .contact__we {
    transform: scale(0.5) translate(-260px, 10px);
  }
  .contacts__socialmedia {
    transform: scale(0.6) translate(-50px, -170px);
  }
  .btn-contact_lets__talk {
    font-size: 25px;
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .box__socialmedia {
    order: 1;
  }
  .contacts__socialmedia {
    text-align: center;
    transform: scale(0.6) translate(0px, 230px);
  }
  .contact__we {
    text-align: center;
    transform: scale(0.4) translate(-260px, -30px);
  }
  #contact__text_with_us_high {
    display: none;
  }
  #contact__text_with_us_low {
    display: block !important;
  }
  .btn-contact_lets__talk {
    width: 200px;
    font-size: 30px;
  }
  .contact__email {
    padding-top: 1em;
  }
  .contacts__ {
    padding-top: 6.5em;
  }
  .contact__fb {
    padding-left: 1em;
    padding-right: 1em;
  }
  .contact__social {
    padding-top: 1.5em;
  }
  .contact__email a {
    font-size: 35px;
  }
  .contact__insta a,
  .contact__fb a,
  .contact__linkedin a {
    font-size: 45px;
  }
}
@media only screen and (min-height: 740px) and (max-height: 840px) and (max-width: 767px) {
  .contacts__ {
    padding-top: 12em !important;
  }
}

@media only screen and (min-height: 680px) and (max-height: 740px) and (max-width: 767px) {
  .contacts__ {
    padding-top: 10em !important;
  }
}
@media only screen and (max-height: 640px) and (max-width: 767px) and (min-width: 400px) {
  .contacts__socialmedia {
    transform: scale(0.42) translate(0px, 140px) !important;
  }
  .contacts__ {
    padding-top: 7em !important;
  }
}
@media only screen and (max-width: 499px) {
  .contacts__socialmedia {
    transform: scale(0.55) translate(0px, 180px);
  }
  .contact__we {
    text-align: center;
    transform: scale(0.35) translate(-260px, -30px);
  }
  .contact__email {
    padding-top: 0.5em;
  }
  .contact__email,
  .contact__insta,
  .contact__fb {
    font-size: 21px;
  }
  .contact_lets__talk {
    padding-top: 2.5em;
  }
}
@media only screen and (max-width: 400px) {
  .contacts__socialmedia {
    transform: scale(0.5) translate(0px, 180px);
  }
  .contact__we {
    transform: scale(0.37) translate(-260px, -35px);
  }
}
</style>
