<template>
  <section class="projects__">
    <div class="row">
      <div class="box__info_p col-md-6">
        <div class="col-md-11 box__panel">
          <div class="panel panel-project">
            <div class="panel-body">
              <div class="row">
                <div
                  class="d-flex align-items-center justify-content-center icon__subject"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="67.107"
                    height="67.108"
                    viewBox="0 0 67.107 67.108"
                  >
                    <path
                      style="fill: #707070;
                        opacity: 0.301;"
                      d="M14.342,31.977,31.981,14.341,26.2,8.558l-8.084,8.084a1.038,1.038,0,0,1-1.469,0l-1.469-1.469a1.038,1.038,0,0,1,0-1.469L23.26,5.619l-4.409-4.41a4.157,4.157,0,0,0-5.88,0L1.213,12.967a4.158,4.158,0,0,0,0,5.88l13.129,13.13ZM65.258,16.668a6.3,6.3,0,0,0,0-8.9L59.327,1.834a6.3,6.3,0,0,0-8.906,0l-6.032,6.03L59.226,22.7ZM41.423,10.832,2.5,49.753.038,63.835A2.782,2.782,0,0,0,3.26,67.054l14.083-2.469L56.26,25.667Zm24.461,37.41-4.409-4.409-8.084,8.084a1.038,1.038,0,0,1-1.469,0l-1.469-1.469a1.04,1.04,0,0,1,0-1.469l8.084-8.084-5.785-5.785L35.112,52.746,48.247,65.879a4.157,4.157,0,0,0,5.88,0L65.884,54.122a4.157,4.157,0,0,0,0-5.88Z"
                      transform="translate(0.005 0.01)"
                    />
                  </svg>
                </div>
                <div class="col info-col">
                  <h4 class="subject__project">Design</h4>
                  <p class="description__project">
                    We create App, Web and Logo designs. We provide our
                    customers designs that are up to current trends and UI & UX
                    standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-11 box__panel pt-panel">
          <div class="panel panel-project">
            <div class="panel-body">
              <div class="row">
                <div
                  class="d-flex align-items-center justify-content-center icon__subject"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="83.889"
                    height="67.101"
                    viewBox="0 0 83.889 67.101"
                  >
                    <path
                      style="fill: #707070;opacity: 0.301;"
                      d="M36.533,67.073l-8-2.32A1.575,1.575,0,0,1,27.463,62.8L45.354,1.173A1.575,1.575,0,0,1,47.307.1l8,2.32a1.575,1.575,0,0,1,1.075,1.953L38.486,66A1.571,1.571,0,0,1,36.533,67.073ZM21.591,52.367l5.7-6.082a1.573,1.573,0,0,0-.1-2.254L15.313,33.585,27.188,23.139a1.562,1.562,0,0,0,.1-2.254l-5.7-6.082a1.574,1.574,0,0,0-2.228-.066L.476,32.432a1.562,1.562,0,0,0,0,2.294L19.363,52.433a1.563,1.563,0,0,0,2.228-.066Zm42.885.079L83.363,34.739a1.562,1.562,0,0,0,0-2.294L64.476,14.725a1.587,1.587,0,0,0-2.228.066l-5.7,6.082a1.573,1.573,0,0,0,.1,2.254L68.526,33.585,56.652,44.031a1.562,1.562,0,0,0-.1,2.254l5.7,6.082A1.575,1.575,0,0,0,64.476,52.446Z"
                      transform="translate(0.025 -0.037)"
                    />
                  </svg>
                </div>
                <div class="col info-col">
                  <h4 class="subject__project">Development</h4>
                  <p class="description__project">
                    Frontend and Backend development for your web applications.
                    Websites, e-Commerce applications with the latest
                    technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-11 box__panel pt-panel">
          <div class="panel panel-project">
            <div class="panel-body">
              <div class="row">
                <div
                  class="d-flex align-items-center justify-content-center icon__subject"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="75.495"
                    height="67.106"
                    viewBox="0 0 75.495 67.106"
                  >
                    <path
                      style="fill: #707070;
                            opacity: 0.301;"
                      d="M75.495,31.456A8.35,8.35,0,0,0,71.3,24.232V4.2A4.237,4.237,0,0,0,67.106,0a4.184,4.184,0,0,0-2.619.92L53.343,9.837a31.812,31.812,0,0,1-19.79,6.94H8.388A8.388,8.388,0,0,0,0,25.165V37.747a8.388,8.388,0,0,0,8.388,8.388h4.417a31.85,31.85,0,0,0-.286,4.194A33.2,33.2,0,0,0,15.87,64.87a4.113,4.113,0,0,0,3.722,2.236h9.736a4.167,4.167,0,0,0,3.395-6.627,16.691,16.691,0,0,1-3.427-10.15,15.861,15.861,0,0,1,.578-4.194h3.68a31.805,31.805,0,0,1,19.788,6.94l11.145,8.916A4.192,4.192,0,0,0,71.3,58.718V38.682A8.353,8.353,0,0,0,75.495,31.456ZM62.912,49.992,58.58,46.526a40.212,40.212,0,0,0-25.027-8.779V25.165A40.212,40.212,0,0,0,58.58,16.386l4.332-3.465Z"
                    />
                  </svg>
                </div>
                <div class="col info-col">
                  <h4 class="subject__project">Marketing</h4>
                  <p class="description__project">
                    Online Marketing, SEO, SEM, Analytics and Social Media
                    Marketing. We help businesses with fine tuning strategies to
                    stand out on the digital platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="projectwe__box col-md-6">
        <div class="project__work">
          <div class="we__work">
            <div class="--we__work">
              <div data-depth="0.6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="426.879"
                  height="334.15"
                  viewBox="0 0 426.879 334.15"
                >
                  <g transform="translate(-376.099 -646.945)">
                    <g transform="translate(508.715 672.05)">
                      <path
                        style="fill: #204366;"
                        d="M1659.85,2163.142l2.19,61.051s8.752,3.432,12.792,0c-.5-8.578,3.7-65.343,3.7-65.343Z"
                        transform="translate(-1593.759 -1949.932)"
                      />
                      <path
                        style="fill: #153351;"
                        d="M1486.058,2581s-25.716,12.782-34.418,18.3c12.8,8.238,80.8,7.43,85.584,1.717,2.062-2.467-38.375-20.019-38.375-20.019Z"
                        transform="translate(-1417.778 -2306.739)"
                      />
                      <path
                        style="fill: #234f82;"
                        d="M1383.378,1605.38s-28.635,5.34-36.353,21.018c-9.089,18.444-17.167,62.2,19.193,69.49s76.243,15.872,92.407,3.86c23.3-17.322-4.544-22.733-22.217-35.6C1406.653,1642.494,1457.595,1600.661,1383.378,1605.38Z"
                        transform="translate(-1322.836 -1481.821)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M2135.453,2640.418s-4.882,12.2.7,15.689,43.233,8.368,42.535,2.786-24.405-20.569-24.405-20.569S2137.544,2629.6,2135.453,2640.418Z"
                        transform="translate(-1994.075 -2352.381)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M2320.84,2528.729s-3.789,9.471.54,12.177,33.558,6.5,33.016,2.167-18.943-15.966-18.943-15.966S2322.463,2520.34,2320.84,2528.729Z"
                        transform="translate(-2151.14 -2259.008)"
                      />
                      <path
                        style="fill: #525354;"
                        d="M1779.65,1843.484l18.392-3.574,5.012,12.029a9.212,9.212,0,0,0,6.965,5.538c11.47,1.937,38.851,6.769,41.195,9.106,2.963,2.963,26.5,80.538,26.5,80.538s-12.029,4.009-14.295,6.276c-3.486-4.707-27.543-61.538-27.543-61.538l-49.16-17.084Z"
                        transform="translate(-1695.016 -1680.36)"
                      />
                      <path
                        style="fill: #353535;"
                        d="M1534.7,1870.585s4.532,31.38,17.084,34.169,59.619,10.459,59.619,10.459l18.478,73.214s16.039-4.88,18.828-2.091c-.218-10.371-8.575-65.656-11.381-83.97a8.877,8.877,0,0,0-6.7-7.286l-51.123-14.213L1572.615,1863Z"
                        transform="translate(-1487.981 -1699.876)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M1509.92,1202.574l9.937-1.744s3.661,10.634,14.468,10.99,11.157-2.441,11.157-3.487-4.068-8.541-4.068-8.541l5.695-.291,7.612,13.1,11.863,94.4-47.941,9.414Z"
                        transform="translate(-1467.037 -1139.076)"
                      />
                      <path
                        style="fill: #edd3d6;"
                        d="M1588.12,1362.39s-2.441,26.671-26.323,37.132-10.461,80.1-10.461,80.1l60.84-25.97Z"
                        transform="translate(-1498.865 -1276.753)"
                      />
                      <path
                        style="fill: #ea5546;"
                        d="M1483.437,1229.85s-19.868-29.285-20.741-29.285-5.3-4.475,1.336-4.475C1465.428,1196.088,1481.7,1202.306,1483.437,1229.85Z"
                        transform="translate(-1425.217 -1136.194)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1475,1225l10.046,8.08a10.483,10.483,0,0,1,3.015,3.921l7.68,17.285,6.625,39.4-24.75-1.042Z"
                        transform="translate(-1437.522 -1160.629)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1520.62,1693.493l2.746,58.313s15.689-13.336,21.7-12.552c.785-8.106-3.268-50.729-3.268-50.729S1522.19,1674.144,1520.62,1693.493Z"
                        transform="translate(-1476.08 -1548.063)"
                      />
                      <path
                        style="fill: #591c1b;"
                        d="M1916.989,1432.27s-18.74-5.752-22.837-7.147-15.862-4.445-16.124-5.928-2.528-7.584-3.139-9.761-4.009-21.7-4.009-21.7,6.405,27.773,8.455,29.374C1883.577,1420.416,1916.989,1432.27,1916.989,1432.27Z"
                        transform="translate(-1772.125 -1298.171)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1770.3,1234.439c-3.232-9.589-6.775-18.814-7.92-28.965a33.025,33.025,0,0,0-6.63-.774v.7c-2.093,4.884,18.486,106,18.486,106l8.541-1.22-4.394-52.646c-3.734-8.668-9.56-23.188-9.56-23.188Z"
                        transform="translate(-1674.693 -1143.471)"
                      />
                      <path
                        style="fill: #4f6e8e;"
                        d="M1321.776,1661.736l205.356-59.446-92.917-16.91-201.346,47.069Z"
                        transform="translate(-1232.87 -1465.228)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M2148.544,1614.466s2.309,4.01,3.747,4.272,12.552.915,13.6-.523.567-10.852-8.8-13.336-12.247-1.308-12.247-1.308Z"
                        transform="translate(-2003.68 -1480.367)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M2163.689,1649.341s2.048-5.274,4.271-4.75,5.727,9.392,5.727,9.392-5.84.566-6.951-.108S2160.747,1652.654,2163.689,1649.341Z"
                        transform="translate(-2018.825 -1515.242)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1853.76,1406.548c1.083,3.25,2.786,6.346,4.179,9.6.356,1.071.439,2.322.74,3.481l-1.857-22.374-6.191-.421.353,1.049C1851.9,1400.82,1852.831,1403.607,1853.76,1406.548Z"
                        transform="translate(-1755.009 -1305.862)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1849.825,1415.595c-1.393-3.25-3.1-6.346-4.179-9.6-.929-2.941-1.857-5.727-2.786-8.668l-.353-1.049-1.477-.1s5.826,14.514,9.559,23.189l-.025-.292C1850.265,1417.92,1850.181,1416.666,1849.825,1415.595Z"
                        transform="translate(-1746.895 -1305.313)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1830.639,1256.354s-12.552-27.02-17.432-37.3c-2.763-5.822-8.933-8.292-13.767-9.34,1.146,10.151,4.689,19.376,7.921,28.965l6.191.421,1.857,22.374a6.562,6.562,0,0,0,.344,1.008,6.562,6.562,0,0,1-.344-1.008l.025.292c1.311,3.046,2.365,5.373,2.819,6.1,1.743,2.786,24.405,8.891,38.351,13.946-3.312-13.248,8.543-9.588,8.543-9.588Z"
                        transform="translate(-1711.743 -1147.706)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M1888.384,1720.052s2.309,4.009,3.747,4.27,12.552.915,13.6-.523.567-10.852-8.8-13.337-12.245-1.306-12.245-1.306Z"
                        transform="translate(-1783.789 -1569.609)"
                      />
                      <path
                        style="fill: #8d2b26;"
                        d="M1405.507,1225s-12.727,1.568-12.727,18.827,9.937,59.446,12.552,61.014,67.641,6.965,67.641,6.965-2.876-11.244,7.845-10.459c-15.689-5.23-59.1-14.643-59.1-14.643l-11.507-31.366Z"
                        transform="translate(-1368.028 -1160.629)"
                      />
                      <path
                        style="fill: #591c1b;"
                        d="M1505.42,1402.93l8.716,34.865,13.132,1.627-11.854-3.195Z"
                        transform="translate(-1463.233 -1311.018)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M1932.82,1778.823s8.745,2.659,13.6-.523c0,0,1.133-.857.262-3.995S1946.389,1776.659,1932.82,1778.823Z"
                        transform="translate(-1824.477 -1624.11)"
                      />
                      <path
                        style="fill: #ea5546;"
                        d="M1522.434,1188.579l-.323-.619c-1.034-1.98-4.148-10.333-7.405-16.058-3.051-5.363-6.256-8.176-7.148-8.252-2.034-.175-21.557,1.045-21.557,1.045l-13.776,1.918s-3.312-2.614-2.091-3.312,10.835-5.379,18.3-6.1c10.809-1.046,20.571-.175,23.528,1.568S1522.086,1166.961,1522.434,1188.579Z"
                        transform="translate(-1433.18 -1102.942)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M1574.12,1146.538a16.2,16.2,0,0,0,14.468,10.99c11.635.929,11.07-2.528,11.157-3.487s-4.068-8.541-4.068-8.541-4.866-.132-5.781-1.44c-2.048-2.925-1.7-8.358-1.7-8.358s-10.242-1.439-10.5.915.35,7.975-.435,8.629A12,12,0,0,1,1574.12,1146.538Z"
                        transform="translate(-1521.299 -1084.784)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M1606.935,1122.148s1.617,14.22.043,14.338c-6.965.523-8.977-5.4-10.547-13.423S1606.935,1122.148,1606.935,1122.148Z"
                        transform="translate(-1540.038 -1071.243)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M1553.529,949a18.8,18.8,0,0,1-7.486-10.417c-.389-1.319-1.2-1.523-2.151-1.778.088-.54,1.746-.664.429-1.628a1.791,1.791,0,0,1-.565-1.907c.759-2.322.17-4.551.13-6.811a9.911,9.911,0,0,1,1.173-5.452,14.976,14.976,0,0,0,1.682-6.066c.252-2.249,1.351-3.459,3.325-3.814a4.813,4.813,0,0,0,1.669-.43c2.569-1.548,4.808-1.458,6.674.788,2.336.155,4.435-1.135,6.568-2.062,1.806-.785,2.911-.328,3.515,1.518a22.952,22.952,0,0,0,3.517,6.676c1.548,2.07,1.131,4.853,1.158,7.4.016,1.362.547.642,1.023.522,1.712,1.842.063,4.334.464,6.422-1.26.678-1.317,2.04-1.616,3.216-1,3.95-2.492,7.68-5.371,10.495-1.481,1.447-1.548.726-1.763,2.167-1.277-1.035-1.74,1.218-3.384,1.936C1559.369,951.16,1556.22,951.239,1553.529,949Z"
                        transform="translate(-1495.577 -893.589)"
                      />
                      <path
                        style="fill: #1c1c1c;"
                        d="M1531.818,828.032c-.734.237-1.608-.014-1.687-.6-.373-2.834-1.858-1.184-3.214-.974-4.238.656-5.4,3.353-6.913,7.739a31.1,31.1,0,0,0-1.444,12.923c.059.893.291,1.98-1.131,1.936-1.483-1.808-1.361-4.374-2.028-6.568.669-.813,1.317-1.588.867-2.771-.872-2.286-.548-4.868-.762-7.317-.215-2.487.221-5.122-2.24-6.667-1.328-.834-1.135-2.531.3-3.953a27.681,27.681,0,0,1,4.684-3.251c-1.02-1.083-2.207-.232-3.1-.791-.721-.454-1.58-.69-1.359-1.907.184-1.012.929-1.268,1.721-1.46a31.125,31.125,0,0,1,7.3-.481c-.13-.452-.135-.687-.243-.806-.828-.921-3.108-.464-2.648-2.364.412-1.689,2.322-1.181,3.518-1.28,6.1-.5,12.2-.854,17.393,2.868a3.482,3.482,0,0,1,.3,3.224C1539.534,821.03,1536.99,825.7,1531.818,828.032Z"
                        transform="translate(-1469.109 -809.131)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M1638,845.4c4.224-3.421,7.648-7.462,8.776-13.243.155-.816.167-1.652.246-2.477,3.373,3.755,7.654,6.781,6.965,13.511-.3,2.9.31,5.812.042,8.716a2.066,2.066,0,0,0,1.478,2.551c-.794,1.238-.758,2.816-1.628,4.024-.752.969.076,2.3-1.439,3.828-.128-2.907-.31-5.354-.322-7.818a5.376,5.376,0,0,0-1.562-4.232,12.98,12.98,0,0,1-3.049-6.243c-.348-1.381-.906-1.9-2.422-1.05C1642.843,844.219,1640.509,845.363,1638,845.4Z"
                        transform="translate(-1575.291 -826.499)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M1737.886,993.764c.28-1.41-.941-3.63,1.238-4.024,2.426,2.477,2.012,7.653-.839,10.416-1.074-.016-.718-1.152-.542-1.924A13.376,13.376,0,0,0,1737.886,993.764Z"
                        transform="translate(-1659.427 -961.784)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M1523.876,1024.461c1.684-.271,1.15,1.3,1.334,2.2.292,1.435.464,2.91.693,4.368.381,1.057,3.226,1.857,0,3.292C1522.186,1032.443,1521.3,1028.1,1523.876,1024.461Z"
                        transform="translate(-1477.588 -991.104)"
                      />
                      <path
                        style="fill: #8d3937;"
                        d="M1631.6,1093.54c.271.836-1.925,2.167-4.246,2.565-3.031.517-5.3-.819-4.832-1.122C1625.265,1095.283,1628.734,1095.825,1631.6,1093.54Z"
                        transform="translate(-1562.155 -1049.517)"
                      />
                      <path
                        style="fill: #50242b;"
                        d="M1569.74,999.216c1.364-1.463,9.722-5.017,8.848-2C1575.634,996.9,1573.108,998.08,1569.74,999.216Z"
                        transform="translate(-1517.597 -967.106)"
                      />
                      <path
                        style="fill: #8d3937;"
                        d="M1654.882,1005.75c1.689,3.642,1.774,7.97-.542,10.443C1656.764,1011.962,1653.763,1009.211,1654.882,1005.75Z"
                        transform="translate(-1589.103 -975.316)"
                      />
                      <path
                        style="fill: #4a242a;"
                        d="M1679.492,988.716a12.881,12.881,0,0,0-5.942.017C1673.55,987.254,1676.332,987,1679.492,988.716Z"
                        transform="translate(-1605.339 -959.911)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M1624.159,1082.476c-3.1.656-9.168,1.511-12.439,2.193C1617.11,1080.857,1620.651,1081.621,1624.159,1082.476Z"
                        transform="translate(-1553.079 -1039.577)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M1563.47,1087.981s1,7.739,14.564,11.948a28.72,28.72,0,0,0,6.524-5.961c1.068-1.183,4.733-8.427,5.024-10.789-3.156,4.628-5,9.844-11.872,10.936a14.781,14.781,0,0,1-9.491-1.187C1566.041,1092.074,1566.648,1091.86,1563.47,1087.981Z"
                        transform="translate(-1512.298 -1040.761)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M1641.48,1112.965s1.257,2.334,2.322,2.67a7.532,7.532,0,0,0,1.566-3.325S1641.6,1113.488,1641.48,1112.965Z"
                        transform="translate(-1578.233 -1065.382)"
                      />
                      <path
                        style="fill: #6d2e30;"
                        d="M1598.258,1035.553c.04.912-.684-.136-1.187-.056s-1.328,1.748-1.368.836a1.5,1.5,0,0,1,1.283-1.475C1597.495,1034.776,1598.224,1034.641,1598.258,1035.553Z"
                        transform="translate(-1539.539 -999.869)"
                      />
                      <path
                        style="fill: #6d2e30;"
                        d="M1684.345,1017.589c.04.912-.619-.319-1.483-.255-1.145.085-1.506,1.8-1.548.893s1.122-1.483,1.562-1.548S1684.3,1016.678,1684.345,1017.589Z"
                        transform="translate(-1611.9 -984.538)"
                      />
                      <path
                        style="fill: #1d3563;"
                        d="M1896.71,1732.426l27.987,7.631,61.784-20.466-16.5-6.871Z"
                        transform="translate(-1793.957 -1572.857)"
                      />
                      <path
                        style="fill: #234f82;"
                        d="M2139.3,1557.707l-61.784,20.466,2.33-35.852a3.77,3.77,0,0,1,3.8-4.667l61.864-16.465a3.436,3.436,0,0,1,3.312,4.532Z"
                        transform="translate(-1946.78 -1410.973)"
                      />
                      <path
                        style="fill: #feeaec;"
                        d="M2281.019,1669.01c-.127,3.551.853,5.848-5.263,8.225-3.368,1.311-6.682-.232-6.555-3.783s.322-6.173,5.433-8.653C2274.838,1673.211,2276.209,1670.249,2281.019,1669.01Z"
                        transform="translate(-2108.788 -1532.354)"
                      />
                    </g>
                    <g transform="translate(376.099 646.945)">
                      <path
                        style="fill: #a57367;"
                        d="M1088.566,660.134s-8.275-11.507,1.743-11.219c4.395.127,6.309-2.344,10.061-1.921a8.8,8.8,0,0,1,6.376,13.6c-.073.108-.155.218-.224.328C1101.023,668.847,1091.453,670.353,1088.566,660.134Z"
                        transform="translate(-975.572 -646.946)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M397.99,665.687s-7.06-12.29,2.877-10.99c4.359.573,6.515-1.689,10.2-.887a8.8,8.8,0,0,1,4.953,14.18l-.257.31C409.5,675.624,399.821,676.147,397.99,665.687Z"
                        transform="translate(-392.546 -652.6)"
                      />
                      <path
                        style="fill: #474342;"
                        d="M578.941,2571.412c.382,2.291,25.252,2.647,26.676,1.221s-3.87-25.453-3.87-25.453l-15.273,1.017S577.515,2562.86,578.941,2571.412Z"
                        transform="translate(-547.414 -2253.05)"
                      />
                      <path
                        style="fill: #474342;"
                        d="M944.9,2590.108c2.833,1.416,16.7,2.646,18.327,0s-6.516-20.568-6.516-20.568l-12.626,1.019S939.2,2587.257,944.9,2590.108Z"
                        transform="translate(-854.52 -2271.949)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M706.2,1113.195l-2.878,1.07,2.963,10.273,24.789,3.853,1.382-14.321-7.309-2.07-16.119.635A9.11,9.11,0,0,0,706.2,1113.195Z"
                        transform="translate(-652.671 -1040.016)"
                      />
                      <path
                        style="fill: #262626;"
                        d="M681.657,791.474c-3.532-6.569-5.789-9.473-6.941-15.014a7.426,7.426,0,0,1-1.161-3.365,64.061,64.061,0,0,1-.525-12.942c-.283-3.478-2.043-2.593-2.354-6.068-.475-5.27,8.157-11.044,11.4-15.237,18.506-7.6,22.665,5.586,28.317,13.265-.354,3-1.008,5.972-1.528,8.954-.155,4.414-1,8.747-1.522,13.118a8.455,8.455,0,0,1-1,2.243c-.6,2.477-1.119,2.932-1.857,5.371-1.1,3.672-3.348,5.36-6.614,10.572a2.162,2.162,0,0,1-1.133,1.063c-4.953,1.675-9.776,1.582-14.4-1.083A1.548,1.548,0,0,1,681.657,791.474Z"
                        transform="translate(-625.064 -722.696)"
                      />
                      <path
                        style="fill: #00a8db;"
                        d="M458.15,792.44c3.251,1.466,18.838-.895,50.216-11.7.7-9.761-11.477-31.674-15.689-45.324,7.321,3.137,12.552-.7,14.993-5.572,5.4,15.169,17.683,50.595,12.9,57.528-8.184,11.864-40.4,19.825-52.3,23.01.957,33.124,2.752,76.551,3.216,79.9-14.138,5.027-27.614,3.99-55.6,1.227-2.71-.268.7-42.953-3.486-71.717-20.919-15.943-32.736-26.778-35.824-30.855-2.548-3.365,5.992-51.437,4.967-58.836,4.179,9.414,17.491,4.644,17.782,2.616-1.57,12.812-7.845,36.085-12.028,49.42,4.445,3.405,31.213,12.566,35.68,10.509,1.3.412,1.652,1.586,2.3,2.514,2.453,3.506,5.388,6.549,9.717,7.979,8.114,2.681,16.6.969,21.221-7.643C456.777,794.44,456.93,793.132,458.15,792.44Z"
                        transform="translate(-376.099 -717.009)"
                      />
                      <path
                        style="fill: #00a8db;"
                        d="M683.966,1124.617c.37-.254.749-.494,1.107-.763,1.457-1.1,2.241-.952,3.1.929,2.545,5.613,6.877,8.775,13.243,8.561,5.5-.184,9.23-3.043,11.505-7.928,1.252-2.686,1.214-2.709,3.8-1.337.331.173.635.4.95.6-1.714,6.024-5.055,10.782-11.3,12.463a17.163,17.163,0,0,1-17-4.08,23.745,23.745,0,0,1-5-6.388C684.029,1126.031,683.373,1125.438,683.966,1124.617Z"
                        transform="translate(-636.11 -1049.452)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M741.632,1091.29c5.263,2.941,10.68,2.915,16.222.9.059,1.124.125,2.029.128,3.382.009,4.035-4.643,8.843-8.878,8.642-2.247-.107-7.753-3.777-7.478-7.894C741.746,1094.524,741.622,1093.7,741.632,1091.29Z"
                        transform="translate(-685.039 -1022.512)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M666.6,888.92c.858,4.2.519,8.521,1.252,12.739.2,1.18.293,2.379.433,3.569-4.726-2.238-5.094-5.829-5.126-10.917-.016-2.247-.715-3.655,1.574-3.614C666.188,890.722,666.57,890.143,666.6,888.92Z"
                        transform="translate(-618.628 -851.466)"
                      />
                      <path
                        style="fill: #a57367;"
                        d="M901.21,910.2c.994-5.1.69-10.4,2.525-15.361.105.2.162.506.325.579,3.529,1.6,2.868,2.76,1.774,6.526C904.846,905.349,904.224,908.322,901.21,910.2Z"
                        transform="translate(-819.93 -856.469)"
                      />
                      <path
                        style="fill: #065c70;"
                        d="M679.911,1132.55c4.257,11.656,17.646,16.8,27.4,9.6,2.91-2.15,4.306-5.4,5.8-8.555.155-.333.331-.659.5-.989l.5.212c-2.121,5.955-5.314,10.99-11.9,12.618a17.8,17.8,0,0,1-17.039-4.091,30.949,30.949,0,0,1-6.233-8.314A1.133,1.133,0,0,1,679.911,1132.55Z"
                        transform="translate(-632.056 -1057.385)"
                      />
                      <path
                        style="fill: #c67a60;"
                        d="M727.891,850.96c-2.056,2.2.02-3.1-1.117-5.174a4.2,4.2,0,0,0-5.43-2,4.669,4.669,0,0,1-3.232.028c-6.2-2.04-3.777,6.382-6.191,7.6-1.891.949-2.847-5.4-3.638-6.523-.76-1.21-1.432-6.306-1.933-7.334-2.322-4.944-.681-11.943,1-16.9,4.893-.481,8.454.3,12.193-1.845,2.836-1.625,5.913-1.825,9.145-1.845,1.967-.011,2.6,3.264,3.357,5,2.265,5.2,3.62,10.482,1.039,16.017C732.637,839.15,731.4,847.211,727.891,850.96Z"
                        transform="translate(-654.259 -790.644)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M769,1011.1c3.577,1.124,7.343.4,10.361.249C776.677,1018.723,770.2,1016.347,769,1011.1Z"
                        transform="translate(-708.184 -954.734)"
                      />
                      <path
                        style="fill: #444;"
                        d="M792.042,1051.654c-1.857,1.447-3.169,1.664-4.732.4C788.767,1051.26,790.113,1051.655,792.042,1051.654Z"
                        transform="translate(-723.66 -988.929)"
                      />
                      <path
                        style="fill: #7a3633;"
                        d="M790.191,934.28c1.179,1.888.046,3.223.133,4.6s-.3,3,2.073,2.441c-.464,1.76-1.475,1.142-2.345.9-1.068-.3-1.067-1-.836-1.957s.1-1.955.246-2.921C789.59,936.461,789.867,935.6,790.191,934.28Z"
                        transform="translate(-725.167 -889.805)"
                      />
                      <path
                        style="fill: #1d4368;"
                        d="M595.707,1760.79s-6.1,121.264-5.934,128.872,24.456.619,24.456.619l10.764-85.687,1.062-42.058C610.466,1762.151,595.707,1760.79,595.707,1760.79Z"
                        transform="translate(-556.696 -1588.382)"
                      />
                      <path
                        style="fill: #234f82;"
                        d="M821.576,1766.34c-6.955.989-21.891.944-31.276.714.342,4.549,1.559,22.492,4.188,42.229,0,0,16.177,82.713,19.3,85.608s16.051-3.539,16.051-3.539S827.929,1809.867,821.576,1766.34Z"
                        transform="translate(-726.188 -1593.073)"
                      />
                      <path
                        style="fill: #3f2030;"
                        d="M828.035,870.987a7.719,7.719,0,0,1,4.3,1.548,1.1,1.1,0,0,1,.438,1.422c-.323.645-.865-.2-1.537-.436-1.238-.443-2.447.169-3.772,0a1.177,1.177,0,0,1-1.15-1.441C826.465,871.14,827.154,870.874,828.035,870.987Z"
                        transform="translate(-756.612 -836.288)"
                      />
                      <path
                        style="fill: #402031;"
                        d="M738.214,872.466c-.108.163-.251.642-.529.743a22.706,22.706,0,0,1-4.627.875c-.977.155-1.7-.076-1.141-1.118.608-1.127,4.323-2.444,5.461-1.96A1.335,1.335,0,0,1,738.214,872.466Z"
                        transform="translate(-676.676 -836.238)"
                      />
                      <path
                        style="fill: #4c2833;"
                        d="M744.664,901.9c-.121.857-2.138-.036-3.11-.015-1.166.025-3.261,1.806-3.3.9-.034-.861,2.133-2.057,3.08-2.077C742.485,900.679,744.483,901.047,744.664,901.9Z"
                        transform="translate(-682.2 -861.424)"
                      />
                      <path
                        style="fill: #4e2934;"
                        d="M832.984,901.485c-.279.875-1.494-.61-2.7-.7-.952-.068-2.743.876-2.724.022.022-.929,1.98-1.193,3.138-1.133A2.96,2.96,0,0,1,832.984,901.485Z"
                        transform="translate(-757.679 -860.549)"
                      />
                      <path
                        style="fill: #0c86a3;"
                        d="M610.62,1310.94l2.237,65.794a8.675,8.675,0,0,0,.076.895l.4,2.941s1.93-21.952,1.857-24.261C614.812,1342.476,615.065,1324.406,610.62,1310.94Z"
                        transform="translate(-574.319 -1208.163)"
                      />
                      <path
                        style="fill: #0c86a3;"
                        d="M971.58,1124.471l.7-.342c5.418-2.631,40.426-19.536,51.593-22.668C1020.3,1109.043,990.755,1123.019,971.58,1124.471Z"
                        transform="translate(-879.408 -1031.107)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="info__work">
            <div class="--info__work">
              <div data-depth="0.2">
                <div class="info__title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="250"
                    height="88"
                    viewBox="0 0 250 88"
                  >
                    <text
                      style="fill: #212121;
                font-size: 72px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: 700;"
                      transform="translate(0 70)"
                    >
                      <tspan x="0" y="0">WORK</tspan>
                    </text>
                  </svg>
                </div>
                <div class="box__lines_project">
                  <div class="box__line_1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="184"
                      height="5"
                      viewBox="0 0 184 5"
                    >
                      <line x2="179" transform="translate(2.5 2.5)" />
                    </svg>
                  </div>
                  <div class="box__line_2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="229"
                      height="5"
                      viewBox="0 0 229 5"
                    >
                      <line x2="224" transform="translate(2.5 2.5)" />
                    </svg>
                  </div>
                </div>
                <div class="info__text">
                  <svg
                    id="info__text_high"
                    xmlns="http://www.w3.org/2000/svg"
                    width="414"
                    height="204"
                    viewBox="0 0 414 204"
                  >
                    <text
                      style="fill: #212121;
                font-size: 56px;
                font-family: Montserrat-Light, Montserrat;
                font-weight: 300;"
                      transform="translate(0 54)"
                    >
                      <tspan x="0" y="0">The value</tspan>
                      <tspan x="0" y="68">we offer to you!</tspan>
                    </text>
                  </svg>
                  <svg
                    id="info__text_low"
                    style="visibility: hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    width="414"
                    height="204"
                    viewBox="0 0 264 66"
                  >
                    <text
                      style="fill: #212121;
                      font-size: 27px;
                      font-family: Montserrat-Light, Montserrat;
                      font-weight: 300;"
                      transform="translate(132 26)"
                    >
                      <tspan x="-131.26" y="0">A list of the projects</tspan>
                      <tspan x="-96.282" y="33">we have done!</tspan>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Projects",
  components: {},
  data: function() {
    return {};
  },
  methods: {},
  mounted() {
    new this.$parallax(document.querySelector(".--we__work"));
    new this.$parallax(document.querySelector(".--info__work"));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.projects__ {
  padding-top: 14em;
  padding-left: 7em;
  padding-right: 2em;
}
.project__work {
  transform: translate(0px, 40px);
}
.we__work {
  position: absolute;
  transform: translate(380px, 0px);
}
.info__work {
  transform: translate(0px, 210px);
}
.box__line_1 {
  transform: translate(10px, 0);
}
.box__line_2 {
  transform: translate(150px, 0);
}
.panel-project {
  height: 17.6em;
  padding: 2.5em;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  background-color: #ffffff;
}
.icon__subject {
  width: 120px;
  height: 145px;
}
.description__project {
  color: #212121;
  font-size: 22px;
  font-family: Montserrat-Light, Montserrat;
  font-weight: 300;
}
.subject__project {
  color: #212121;
  font-size: 30px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
}
.pt-panel {
  padding-top: 1.5rem !important;
}
@media only screen and (max-height: 940px) and (min-width: 1801px) {
  .panel-project {
    height: 14em !important;
    padding: 2em !important;
  }
  .description__project {
    font-size: 16px !important;
  }
  .subject__project {
    font-size: 24px !important;
  }
  .icon__subject {
    width: 100px !important;
    height: 125px !important;
  }
  .project__work {
    transform: scale(0.8) translate(-160px, -7px) !important;
  }
}
@media only screen and (max-height: 940px) and (min-width: 1390px) and (max-width: 1801px) {
  .panel-project {
    height: 14em !important;
    padding: 2em !important;
  }
  .description__project {
    font-size: 16px !important;
  }
  .subject__project {
    font-size: 24px !important;
  }
  .icon__subject {
    width: 100px !important;
    height: 125px !important;
  }
  .project__work {
    transform: scale(0.8) translate(-160px, -35px) !important;
  }
}
@media only screen and (max-height: 810px) and (min-width: 1200px) {
  .panel-project {
    height: 11.8em !important;
    padding: 1.5em !important;
  }
  .description__project {
    font-size: 16px !important;
  }
  .subject__project {
    font-size: 21px !important;
  }
  .icon__subject {
    padding: 10px !important;
    width: 60px !important;
    height: 90px !important;
    padding-right: 0px !important;
  }
  .pt-panel {
    padding-top: 1rem !important;
  }
  .project__work {
    transform: scale(0.7) translate(-160px, -108px) !important;
  }
}
@media only screen and (max-height: 810px) and (min-width: 1801px) {
  .panel-project {
    height: 11.8em !important;
    padding: 1.5em !important;
  }
  .description__project {
    font-size: 16px !important;
  }
  .subject__project {
    font-size: 21px !important;
  }
  .icon__subject {
    padding: 10px !important;
    width: 60px !important;
    height: 90px !important;
    padding-right: 0px !important;
  }
  .pt-panel {
    padding-top: 1rem !important;
  }
  .project__work {
    transform: scale(0.7) translate(-160px, -65px) !important;
  }
}
@media only screen and (max-height: 700px) and (min-width: 992px) {
  .project__work {
    transform: scale(0.5) translate(-250px, -130px) !important;
  }
  .panel-project {
    height: 10em !important;
    padding: 1.3em !important;
  }
  .description__project {
    font-size: 13px !important;
  }
  .subject__project {
    font-size: 18px !important;
  }
  .icon__subject {
    padding: 15px !important;
    width: 50px !important;
    height: 80px !important;
    padding-right: 0px !important;
  }
  .box__panel {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .panel-project {
    height: 10.2em !important;
  }
}
@media only screen and (max-width: 1750px) {
  .project__work {
    transform: scale(0.9) translate(0px, 55px);
  }
}
@media only screen and (max-width: 1635px) {
  .project__work {
    transform: scale(0.8) translate(-100px, 3px);
  }
  .panel-project {
    height: 15em;
    padding: 2em;
  }
  .description__project {
    font-size: 18px;
  }
  .subject__project {
    font-size: 26px;
  }
  .icon__subject {
    width: 110px;
    height: 135px;
  }
}
@media only screen and (max-width: 1390px) {
  .panel-project {
    height: 14em;
    padding: 2em;
  }
  .description__project {
    font-size: 16px;
  }
  .subject__project {
    font-size: 24px;
  }
  .icon__subject {
    width: 100px;
    height: 125px;
  }
  .project__work {
    transform: scale(0.8) translate(-100px, -35px);
  }
}
@media only screen and (max-width: 1375px) {
  .project__work {
    transform: scale(0.7) translate(-160px, -15px);
  }
}
@media only screen and (max-width: 1270px) {
  .panel-project {
    height: 11.8em;
    padding: 1.5em;
  }
  .description__project {
    font-size: 15px;
  }
  .subject__project {
    font-size: 21px;
  }
  .icon__subject {
    padding: 10px;
    width: 60px;
    height: 90px;
    padding-right: 0px;
  }
  .pt-panel {
    padding-top: 1rem !important;
  }
  .project__work {
    transform: scale(0.7) translate(-160px, -108px);
  }
}
@media only screen and (max-width: 1200px) {
  .projects__ {
    padding-top: 12em;
  }
  .box__panel {
    max-width: 100% !important;
    transform: translate(-45px, 0px);
  }
  .pt-panel {
    padding-top: 1rem !important;
  }
}
@media only screen and (max-width: 1180px) {
  .project__work {
    transform: scale(0.6) translate(-220px, -94px);
  }
}
@media only screen and (max-width: 1027px) {
  .project__work {
    transform: scale(0.5) translate(-250px, -168px);
  }
  .panel-project {
    height: 10em;
    padding: 1.3em;
  }
  .description__project {
    font-size: 13px;
  }
  .subject__project {
    font-size: 18px;
  }
  .icon__subject {
    padding: 12px;
    width: 50px;
    height: 80px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 992px) {
  .box__panel {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .icon__subject {
    padding: 15px;
    padding-right: 0px;
  }
  .panel-project {
    height: 10.2em;
  }
}
@media only screen and (max-width: 870px) {
  .panel-project {
    width: 26em;
  }
  .panel-project {
    height: 10em;
    padding: 1.2em;
  }
  .description__project {
    font-size: 12px;
  }
  .subject__project {
    font-size: 16px;
  }
  .project__work {
    transform: scale(0.44) translate(-270px, -167px);
  }
}
@media only screen and (max-width: 927px) {
  .project__work {
    transform: scale(0.44) translate(-270px, -160px);
  }
}
@media only screen and (max-height: 880px) and (max-width: 767px) and (min-width: 550px) {
  .description__project {
    font-size: 14px !important;
  }
  .subject__project {
    font-size: 22px !important;
  }
  .panel-project {
    height: 11.5em !important;
    padding: 1.5em !important;
  }
  .icon__subject {
    width: 80px !important;
    height: 100px !important;
  }
}
@media only screen and (max-height: 780px) and (max-width: 767px) and (min-width: 499px) {
  .projectwe__box {
    height: 120px !important;
    transform: translate(0px, -5px) !important;
  }
  .project__work {
    transform: scale(0.45) translate(-85px, -270px) !important;
  }
  .description__project {
    font-size: 13px !important;
  }
  .subject__project {
    font-size: 17px !important;
  }
  .panel-project {
    height: 10em !important;
  }
  .icon__subject {
    width: 60px !important;
    height: 80px !important;
  }
  .info-col {
    padding: 0 !important;
  }
  .box__info_p {
    padding-top: 1em !important;
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .projectwe__box {
    height: 150px;
    padding-right: 0 !important;
    display: flex !important;
    justify-content: center !important;
  }
  .box__info_p {
    order: 1;
    padding-top: 1em;
  }
  .we__work {
    display: none;
  }
  #info__text_high {
    display: none;
  }
  #info__text_low {
    visibility: unset !important;
    transform: translate(90px, -30px);
  }
  .info__title {
    transform: translate(160px, 0px);
  }
  .box__lines_project {
    transform: translate(100px, 0px);
  }
  .project__work {
    transform: scale(0.6) translate(-90px, -260px);
  }
  .projects__ {
    padding-top: 8em;
    padding-left: 2em;
  }
  .box__panel {
    transform: unset;
  }
  .info-col {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .panel-project {
    width: 100%;
    height: 13.5em;
    padding: 2em;
  }
  .description__project {
    font-size: 17px;
  }
  .subject__project {
    font-size: 24px;
  }
  .icon__subject {
    width: 90px;
    height: 115px;
  }
}
@media only screen and (max-height: 640px) and (max-width: 767px) {
  .projectwe__box {
    height: 130px;
  }
  .projects__ {
    padding-top: 6em;
  }
  .project__work {
    transform: scale(0.4) translate(-85px, -220px) !important;
  }
}
@media only screen and (max-width: 550px) {
  .description__project {
    font-size: 14px;
  }
  .subject__project {
    font-size: 22px;
  }
  .panel-project {
    height: 11.5em;
    padding: 1.5em;
  }
  .icon__subject {
    width: 80px;
    height: 100px;
  }
}
@media only screen and (max-width: 499px) {
  .projectwe__box {
    height: 120px;
    transform: translate(0px, -5px);
  }
  .project__work {
    transform: scale(0.45) translate(-85px, -270px);
  }
  .description__project {
    font-size: 12px;
  }
  .subject__project {
    font-size: 16px;
  }
  .panel-project {
    height: 10em;
  }
  .icon__subject {
    width: 50px;
    height: 80px;
    padding-left: 5px;
    padding-right: 10px;
  }
  .info-col {
    padding: 0 !important;
  }
  .box__info_p {
    padding-top: 1em;
  }
}
@media only screen and (max-height: 730px) and (max-width: 399px) {
  .description__project {
    font-size: 11px;
  }
  .subject__project {
    font-size: 14px;
  }
  .panel-project {
    height: 8.5em;
    padding: 1em;
  }
  .icon__subject {
    width: 60px;
    height: 60px;
    padding-left: 15px;
    padding-right: 10px;
  }
  .pt-panel {
    padding-top: 0.5rem !important;
  }
}
@media only screen and (max-width: 320px) {
  .projects__ {
    padding-top: 5em;
  }
  .description__project {
    font-size: 9px;
  }
  .subject__project {
    font-size: 11px;
  }
  .panel-project {
    height: 7.2em;
    padding: 1em;
  }
  .icon__subject {
    width: 49px;
    height: 50px;
  }
  .pt-panel {
    padding-top: 0.5rem !important;
  }
}
</style>
