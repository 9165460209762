<template>
  <section class="index__">
    <div data-depth="0.1">
      <div data-aos="zoom-out-up" data-aos-duration="1500">
        <div class="we__ d-flex justify-content-end">
          <svg
            class="its__we"
            xmlns="http://www.w3.org/2000/svg"
            width="419.424"
            viewBox="0 0 419.424 515.575"
          >
            <g transform="translate(-208.112 -63.027)">
              <g transform="translate(273.786 224.355)">
                <path
                  style="fill: #c54342;"
                  d="M944.132,812.192V747.82H622.28V1005.3H815.391V940.93H686.652V812.192Z"
                  transform="translate(-590.381 -747.82)"
                />
                <path
                  style="fill: #c54342;"
                  d="M744.362,1158.57H615.621v64.369H744.362V1351.68H486.88v64.369H808.732V1158.57Z"
                  transform="translate(-486.88 -1061.803)"
                />
              </g>
              <g transform="translate(468.029 63.027)">
                <path
                  style="fill: #c67a60;"
                  d="M1423.871,160.744a19.239,19.239,0,0,1-6.832-10.274c-.321-1.223-1.1-1.517-2.017-1.885.108-.471,1.727-.344.488-1.381a1.621,1.621,0,0,1-.471-1.769c.839-1.951.36-4,.419-6.005a7.568,7.568,0,0,1,1.376-4.66,11.251,11.251,0,0,0,1.885-5.133,3.141,3.141,0,0,1,3.4-2.91,4.919,4.919,0,0,0,1.649-.146c2.566-1.006,4.738-.615,6.458,1.637,2.264.471,4.361-.382,6.476-.9,1.791-.441,2.846.12,3.352,1.84a22.3,22.3,0,0,0,3.136,6.4c1.414,2.05.891,4.455.808,6.714-.045,1.206.5.643.97.6,1.586,1.885-.123,3.842.181,5.753-1.256.422-1.369,1.621-1.71,2.62-1.143,3.355-2.754,6.448-5.675,8.533-1.5,1.072-.85,2.438-1.124,3.687a3.951,3.951,0,0,0-4.057-.775A8.9,8.9,0,0,1,1423.871,160.744Z"
                  transform="translate(-1390.549 -111.209)"
                />
                <path
                  style="fill: #1c1c1c;"
                  d="M1418.286,80.911c-.723.106-1.564-.236-1.614-.773-.236-2.561-1.755-1.308-3.084-1.312-4.151-.016-5.4,2.205-7.068,5.878a24.276,24.276,0,0,0-1.96,11.237c.019.8.2,1.793-1.178,1.555-1.366-1.809-1.135-4.064-1.691-6.1.686-.624,1.35-1.22.961-2.33-.749-2.146-.323-4.387-.424-6.585s.433-4.5-1.885-6.217c-1.256-.926-.994-2.4.471-3.456a24.171,24.171,0,0,1,4.693-2.217c-.942-1.1-2.134-.518-2.973-1.136-.681-.5-1.505-.834-1.239-1.885.236-.869.964-.989,1.736-1.051a34.01,34.01,0,0,1,7.124.6c-.108-.417-.1-.624-.2-.747-.766-.942-3-.85-2.474-2.464.471-1.437,2.309-.707,3.475-.638,5.951.412,11.9.959,16.795,4.985a2.924,2.924,0,0,1,.148,2.9C1426.1,75.8,1423.422,79.573,1418.286,80.911Z"
                  transform="translate(-1378.773 -63.027)"
                />
                <path
                  style="fill: #feeaec;"
                  d="M1410.376,315.58a2.583,2.583,0,0,0,1.885.1c1.937,2.8,3.9,5.477,7.183,7.011a14.773,14.773,0,0,0,19.382-5.89,8.343,8.343,0,0,1,.761-1.147c.671.019.707.752,1.178,1.022.942.733.806,1.8.822,2.789.038,2.3-.1,4.606.042,6.9a4.247,4.247,0,0,1-3.5,4.853c-8.066,2.186-18.534,34.69-26.588,36.907-1.329.365-.28-28.706-1.52-30.132-.751-1.769-.471-3.638-.471-5.466q-.057-6.636,0-13.273C1409.556,317.981,1409.384,316.638,1410.376,315.58Z"
                  transform="translate(-1386.388 -256.082)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M1448.323,295.551a1.7,1.7,0,0,1-.29,1.8,15.6,15.6,0,0,1-26.734-.212,1.835,1.835,0,0,1-.3-1.56c3.437-.262,6.069-1.578,6.884-5.275,3.18.728,6.177-.523,9.256-.869,1.578-.179,3.124-.471,4.281,1.013A6.934,6.934,0,0,0,1448.323,295.551Z"
                  transform="translate(-1395.119 -235.982)"
                />
                <path
                  style="fill: #8d2b26;"
                  d="M1525.553,355.335c-.869-1.081-.327-2.059.2-3.063,2.85-5.463,5.817-10.877,7.324-16.929.184-.735.295-1.72,1.47-1.661,7.588,4.5,14.135,3.979,16.727,23.274a119.88,119.88,0,0,1,1.482,36.358c-1.979,15.742-10.13,33.531-11.367,33.571-5.454.181-19.189-23.269-21.733-23.813s2.313-8.966,5.89-47.742"
                  transform="translate(-1470.045 -269.917)"
                />
                <path
                  style="fill: #8d2b26;"
                  d="M1334.049,351.571a60.435,60.435,0,0,1-6.832-16.119c-.153-.66-.165-1.55-1.253-1.442-2.592,1.56-9.058,6.632-11.308,14.743-4.24,15.2-3.371,37.743-2.733,44.453,1.1,11.544,10.107,13.282,10.915,13.266l10.255,19.083s47.516,1.593,49.655,1.593-47.2-70.7-47.4-71.472S1334.049,351.571,1334.049,351.571Z"
                  transform="translate(-1311.387 -270.164)"
                />
                <path
                  style="fill: #edd3d6;"
                  d="M1412.292,341.578c6.983-1.138,13.694-7.273,20.482-9.105q3.842-1.039,7.645-2.191a2.965,2.965,0,0,0,2.408-3.411c-.2-3.392-.1-6.806-.12-10.21l.577-1.1c1.037,1.013.931,2.342.942,3.609.033,5.68,0,11.36.017,17.04,0,1.847,2.827,51.45,2.591,53.254-10.366,0-22.242,1.126-32.6,1.121C1414.158,389.468,1411.2,342.62,1412.292,341.578Z"
                  transform="translate(-1388.334 -256.066)"
                />
                <path
                  style="fill: #ea5546;"
                  d="M1382.859,336.082q-.1,1.649-.2,3.317a.733.733,0,0,1-.44-.082,6.14,6.14,0,0,0-1.227-3.3c-3.249-5.633-6.243-11.362-7.348-17.879-.026-.148-.236-.264-.36-.4,2.69-4.86,5.02-5.937,9.407-4.349Q1382.776,324.736,1382.859,336.082Z"
                  transform="translate(-1358.699 -253.89)"
                />
                <path
                  style="fill: #ea5546;"
                  d="M1545.523,335.89c-.031-8.677-.86-13.739-.893-22.416,3.868-1.414,6.377-.516,8.538,3a12.718,12.718,0,0,0,1.018,1.275,97.223,97.223,0,0,1-7.419,17.648c-.676,1.26-1.147,1.451-.865,3.1C1545.751,338.571,1545.688,335.893,1545.523,335.89Z"
                  transform="translate(-1489.681 -253.983)"
                />
                <path
                  style="fill: #262626;"
                  d="M1479.11,97.9c4.255-2.434,7.758-5.529,9.105-10.488a20.534,20.534,0,0,0,.346-2.16c3.119,3.8,7.153,7.079,6.189,12.941-.415,2.523.045,5.183-.332,7.72a2.029,2.029,0,0,0,1.329,2.467c-.827.985-.86,2.387-1.757,3.331-.773.752-.026,2.05-1.564,3.185,0-2.591-.068-4.782.023-6.966a4.957,4.957,0,0,0-1.338-3.965,12.473,12.473,0,0,1-2.7-5.956c-.28-1.27-.8-1.809-2.311-1.27A14.97,14.97,0,0,1,1479.11,97.9Z"
                  transform="translate(-1439.597 -80.014)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1463.987,278.221c-3.7-1.312-7.134.671-10.71.912-1.178.08-2.4.707-2.827-1.055q.278-2.394.561-4.787c3.619,1.414,7.211,2.283,11.072.641,2.189-.931.471,1.9,1.7,2.092Q1463.883,277.123,1463.987,278.221Z"
                  transform="translate(-1417.689 -223.755)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1540.023,186.749c.419-1.2-1.02-3.381,1.758-3.331,2.949,2.632,2.184,7.131-1.546,9.065-1.357-.207-.855-1.147-.6-1.8A8.3,8.3,0,0,0,1540.023,186.749Z"
                  transform="translate(-1485.63 -155.054)"
                />
                <path
                  style="fill: #a57367;"
                  d="M1403.429,183.45c1.649,0,1.063,1.31,1.2,2.134.221,1.312.33,2.643.488,3.965.325.989,3.063,2.1-.144,2.914C1401.446,190.28,1400.765,186.308,1403.429,183.45Z"
                  transform="translate(-1380.504 -155.08)"
                />
                <path
                  style="fill: #8d3937;"
                  d="M1473.284,237.35c.235.78-1.92.622-4.194.653-2.971.033-5.169-.45-4.7-.653Z"
                  transform="translate(-1428.298 -196.282)"
                />
                <path
                  style="fill: #8d3937;"
                  d="M1486.535,184.11c1.487,3.461,1.383,7.3-.975,9.169C1488.1,189.872,1485.3,187.017,1486.535,184.11Z"
                  transform="translate(-1444.527 -155.584)"
                />
                <path
                  style="fill: #262626;"
                  d="M1469.744,229.642c-3.049.144-8.981.047-12.194.191C1462.957,227.218,1466.373,228.392,1469.744,229.642Z"
                  transform="translate(-1423.116 -189.337)"
                />
                <path
                  style="fill: #262626;"
                  d="M1426.34,236.587s.641,6.985,13.65,12.625a23.563,23.563,0,0,0,6.6-4.358c1.089-.9,4.966-6.792,5.35-8.844-3.268,3.654-5.287,8.01-12.015,8.01a16.583,16.583,0,0,1-9.188-2.389C1428.663,240.571,1429.266,240.47,1426.34,236.587Z"
                  transform="translate(-1399.259 -195.257)"
                />
                <path
                  style="fill: #262626;"
                  d="M1476.12,246.893s1.121,2.243,2.146,2.693a5.731,5.731,0,0,0,1.668-2.726S1476.217,247.381,1476.12,246.893Z"
                  transform="translate(-1437.311 -203.551)"
                />
                <path
                  style="fill: #234f82;"
                  d="M1402.787,563.425h-64.6l-9.044-31.931a2.839,2.839,0,0,1,2.73-3.614h76.217a2.841,2.841,0,0,1,2.754,3.534Z"
                  transform="translate(-1324.875 -418.367)"
                />
                <path
                  style="fill: #feeaec;"
                  d="M1484.6,586.426c0,3.451-4.259,7.376-7.774,7.376s-7.28-3.727-7.28-7.181a6.4,6.4,0,0,1,6.2-6.391C1476.252,588.393,1477.079,587.008,1484.6,586.426Z"
                  transform="translate(-1432.289 -458.384)"
                />
                <path
                  style="fill: #353535;"
                  d="M1367.88,678.76s-.016,12.847,2.771,17.622,53.728,2.591,58.9,1.395,2.931-19.017,2.931-19.017Z"
                  transform="translate(-1354.571 -533.701)"
                />
                <path
                  style="fill: #262626;"
                  d="M1440.21,1041.7s-2.389,13.193.8,18.757,6.764,4.377,7.362,3.383,6.547-6.872,2.478-22.041S1440.21,1041.7,1440.21,1041.7Z"
                  transform="translate(-1409.203 -806.041)"
                />
                <path
                  style="fill: #262626;"
                  d="M1570.28,1041.7s-2.389,13.193.8,18.757,6.766,4.377,7.362,3.383,6.549-6.872,2.479-22.041S1570.28,1041.7,1570.28,1041.7Z"
                  transform="translate(-1508.63 -806.041)"
                />
                <path
                  style="fill: #525354;"
                  d="M1416.579,764.935s11.308-85.655-4.033-91.045-18.14,11.509-18.018,15.591,11.417,75.355,11.417,75.355Z"
                  transform="translate(-1374.938 -529.176)"
                />
                <path
                  style="fill: #525354;"
                  d="M1547.226,764.935s11.308-85.655-4.033-91.045-18.14,11.509-18.015,15.591,11.412,75.355,11.412,75.355Z"
                  transform="translate(-1474.808 -529.176)"
                />
                <path
                  style="fill: #50242b;"
                  d="M1438.56,166.1c2.12-3.063,4.514-2.961,7.539.386C1443.314,165.462,1441.194,164.223,1438.56,166.1Z"
                  transform="translate(-1408.6 -140.121)"
                />
                <path
                  style="fill: #4a242a;"
                  d="M1501.87,167.8c2.3-3.063,4.832-2.928,6.891.094C1508.761,167.89,1506.078,165.348,1501.87,167.8Z"
                  transform="translate(-1456.995 -141.405)"
                />
                <path
                  style="fill: #6d2e30;"
                  d="M1451.212,187.805c-1.6-1.748-3.063-1.31-4.669.471a2.173,2.173,0,0,1,1.864-2.549C1449.838,185.477,1450.773,186.307,1451.212,187.805Z"
                  transform="translate(-1414.699 -156.787)"
                />
                <path
                  style="fill: #63282a;"
                  d="M1510.909,187.788c-.768-1.279-2.175-1.352-3.769-.4C1507.284,185.107,1510.827,185.145,1510.909,187.788Z"
                  transform="translate(-1461.023 -156.829)"
                />
              </g>
              <g transform="translate(208.112 308.195)">
                <path
                  style="fill: #474342;"
                  d="M325.849,2195.192c.344,2.059,22.689,2.379,23.971,1.1s-3.937-16.02-3.937-16.02l-15.122.53S324.574,2187.51,325.849,2195.192Z"
                  transform="translate(-297.979 -1926.644)"
                />
                <path
                  style="fill: #474342;"
                  d="M208.178,2133.328c.575,2.789,7.412,13.263,10.184,12.943s10.9-16.032,10.9-16.032l-7.734-8.349S207.023,2127.718,208.178,2133.328Z"
                  transform="translate(-208.112 -1882.018)"
                />
                <path
                  style="fill: #234f82;"
                  d="M311.884,1694.68c-6.25.888-19.671.848-28.105.641.306,4.087-2.679,40.05-.313,57.785,0,0-51.122,40.8-49.351,42.45,2.8,2.6,16.981,9.553,16.981,9.553s49.779-34.353,53.064-44.055C306.814,1753.238,313.446,1721,311.884,1694.68Z"
                  transform="translate(-227.954 -1555.453)"
                />
                <path
                  style="fill: #1d4368;"
                  d="M337.692,1686.9s-5.477,108.968-5.331,115.805,21.98.554,21.98.554L364,1726.266l.942-37.8C350.944,1688.123,337.692,1686.9,337.692,1686.9Z"
                  transform="translate(-303.087 -1549.505)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M443.471,1322.036l-2.681.65,1.56,9.48,21.714,6.055,2.747-12.632-6.3-2.622-14.453-1.138A8.22,8.22,0,0,0,443.471,1322.036Z"
                  transform="translate(-385.974 -1270.421)"
                />
                <path
                  style="fill: #262626;"
                  d="M446.769,1151.709c-2.457-6.234-4.165-9.063-4.608-14.135a6.689,6.689,0,0,1-.681-3.124,58.256,58.256,0,0,1,.9-11.607c.115-3.131-1.548-2.528-1.461-5.654.134-4.754,8.446-8.995,11.779-12.394,17.313-4.82,19.636,7.383,23.87,14.842-.634,2.641-1.531,5.221-2.309,7.829-.6,3.922-1.816,7.7-2.745,11.544a7.276,7.276,0,0,1-1.131,1.885c-.8,2.151-1.31,2.5-2.219,4.6-1.369,3.159-3.555,4.429-7.02,8.733a1.932,1.932,0,0,1-1.121.832c-4.6.97-8.891.377-12.736-2.5a1.4,1.4,0,0,1-.518-.851Z"
                  transform="translate(-386.07 -1103.699)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M389.23,1383.482c-.872,8.179.236,11.906-15.928,11.822-.485,17.085-4.712,22.984-4.653,26.023-13.148,2.994-25.066.646-49.749-4.778-4.135-.909,2.749-25.351.422-27.927-2.544-2.813-11.308.236-11.45-6.486-.236-12.3,7.167-39.687,7.642-41.463,1.088-3.769,3.11-6.519,7.525-7.574,4.3-1.03,8.427-2.655,12.632-4.019,1.112.5,1.305,1.59,1.786,2.485,1.819,3.388,4.116,6.413,7.829,8.147,6.959,3.249,14.715,2.617,19.745-4.577.615-.879.888-2.031,2.05-2.518,4.712,2.827,6.168,5.334,10.8,8.276,2.547,1.619,4.667,6.832,4.961,9.478-.132,1.209,7.539,22.244,6.391,33.116"
                  transform="translate(-284.366 -1275.983)"
                />
                <path
                  style="fill: #00a8db;"
                  d="M428.861,1324.431c.356-.188.721-.363,1.07-.565,1.413-.824,2.1-.612,2.66,1.15,1.677,5.277,5.209,8.557,10.912,9.039,4.924.415,8.559-1.741,11.106-5.859,1.4-2.266,1.369-2.29,3.534-.794.278.191.525.424.785.638-2.165,5.183-5.654,9.087-11.4,9.928a15.437,15.437,0,0,1-14.745-5.437A21.322,21.322,0,0,1,429,1326.3C428.767,1325.7,428.246,1325.1,428.861,1324.431Z"
                  transform="translate(-376.634 -1271.643)"
                />
                <path
                  style="fill: #a57367;"
                  d="M463.784,1307.49c4.387,3.18,9.23,3.732,14.371,2.514-.066,1.011-.1,1.826-.236,3.032-.419,3.6-5.084,7.4-8.837,6.773-1.993-.332-6.521-4.189-5.838-7.838C463.544,1310.39,463.522,1309.636,463.784,1307.49Z"
                  transform="translate(-403.085 -1259.479)"
                />
                <path
                  style="fill: #a57367;"
                  d="M434.483,1185c.323,3.842-.436,7.661-.236,11.5.059,1.074,0,2.153,0,3.23-3.979-2.495-3.93-5.739-3.421-10.283.224-2.005-.252-3.336,1.788-3.063C433.929,1186.559,434.332,1186.081,434.483,1185Z"
                  transform="translate(-378.173 -1165.847)"
                />
                <path
                  style="fill: #a57367;"
                  d="M566,1217.953c1.425-4.443,1.715-9.211,3.875-13.443.073.191.092.471.236.554,2.983,1.8,2.269,2.766.9,6.01C569.769,1214.006,568.893,1216.6,566,1217.953Z"
                  transform="translate(-481.686 -1180.76)"
                />
                <path
                  style="fill: #065c70;"
                  d="M426.8,1327.763c2.568,10.851,13.973,16.856,23.439,11.459,2.827-1.609,4.413-4.365,6.081-7.021.174-.28.365-.554.547-.829l.422.236c-2.521,5.091-5.9,9.247-11.954,10a15.988,15.988,0,0,1-14.771-5.452,27.514,27.514,0,0,1-4.686-8.076A1.015,1.015,0,0,1,426.8,1327.763Z"
                  transform="translate(-374.577 -1274.975)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M474.456,1184.412c-2.066,1.743.346-2.763-.45-4.735a3.771,3.771,0,0,0-4.634-2.356,4.183,4.183,0,0,1-2.886-.313c-5.32-2.476-4.045,5.3-6.333,6.125-1.786.646-1.967-5.119-2.556-6.205-.549-1.161-.613-5.779-.942-6.75-1.543-4.655.653-10.729,2.674-14.974,4.417.087,7.513,1.159,11.073-.36,2.7-1.15,5.47-1,8.356-.678,1.758.2,1.972,3.185,2.467,4.818,1.475,4.877,2.12,9.742-.761,14.4C479.941,1174.373,477.981,1181.436,474.456,1184.412Z"
                  transform="translate(-397.705 -1142.098)"
                />
                <path
                  style="fill: #444;"
                  d="M487.34,1262.38c3.074,1.378,6.512,1.133,9.221,1.317C494.327,1265.329,489.467,1265.087,487.34,1262.38Z"
                  transform="translate(-421.558 -1224.997)"
                />
                <path
                  style="fill: #444;"
                  d="M501.456,1275.878c-1.814,1.1-3,1.15-4.266-.139C498.575,1275.183,499.737,1275.675,501.456,1275.878Z"
                  transform="translate(-429.087 -1235.013)"
                />
                <path
                  style="fill: #7a3633;"
                  d="M503.049,1219.22c.853,1.809-.3,2.881-.367,4.116s-.584,2.646,1.59,2.4c-.6,1.522-1.437.862-2.186.554-.921-.382-.846-1.006-.539-1.835s.3-1.732.528-2.592C502.281,1221.1,502.62,1220.367,503.049,1219.22Z"
                  transform="translate(-432.258 -1192.005)"
                />
                <path
                  style="fill: #3f2030;"
                  d="M535.856,1186.117a6.841,6.841,0,0,1,2.827,2.975.991.991,0,0,1-.219,1.319c-.488.377-.617-.506-1.06-.968-.82-.85-2.035-.846-3.037-1.508a1.057,1.057,0,0,1-.346-1.621A1.2,1.2,0,0,1,535.856,1186.117Z"
                  transform="translate(-457.056 -1166.446)"
                />
                <path
                  style="fill: #402031;"
                  d="M479.28,1178.205c-.1.141-.25.568-.5.648a19.8,19.8,0,0,1-4.186.613c-.883.1-1.527-.13-.982-1.044.587-.99,3.972-2.033,4.973-1.557A1.191,1.191,0,0,1,479.28,1178.205Z"
                  transform="translate(-410.909 -1159.537)"
                />
                <path
                  style="fill: #4c2833;"
                  d="M485.994,1195.916c-.2.754-.292,1.833-1.161,1.748-1.041-.1-.9-1.213-.836-2.026.061-.77.273-1.812,1.119-1.732C486.143,1194.007,485.921,1195.143,485.994,1195.916Z"
                  transform="translate(-418.968 -1172.651)"
                />
                <path
                  style="fill: #4e2934;"
                  d="M534.215,1207.227c-.342.749-1.454-.907-2.521-1.112-.843-.163-2.094.942-1.986.177.115-.82,1.213-1.272,2.24-1.1A3.576,3.576,0,0,1,534.215,1207.227Z"
                  transform="translate(-453.94 -1181.256)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M381.616,1415.11l-3.216,21.059,4.7.957S380.485,1434.814,381.616,1415.11Z"
                  transform="translate(-338.282 -1341.746)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M459.087,1540.33l-39-21.54,53.384,17.954S473.884,1541.168,459.087,1540.33Z"
                  transform="translate(-370.151 -1421)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M571.542,1445.63l-1.022,16.178a18.234,18.234,0,0,1,4.947,1.833C570.308,1461.337,572.921,1452.606,571.542,1445.63Z"
                  transform="translate(-485.142 -1365.076)"
                />
                <path
                  style="fill: #c67a60;"
                  d="M397.638,1478.113s3.571-6.479,7.379-5.89a29.915,29.915,0,0,0-3.263-5.513s-4.189-1.477-6.361-.8-4.745,4.738-4.745,4.738S389.419,1476.07,397.638,1478.113Z"
                  transform="translate(-347.595 -1380.443)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M317.8,1564.3l43.617,1.025-53.184-5.138S307.638,1563.592,317.8,1564.3Z"
                  transform="translate(-284.638 -1452.647)"
                />
                <path
                  style="fill: #0c86a3;"
                  d="M544.57,1517.248l5.572,1.819-2.827-2.471S547.536,1516.263,544.57,1517.248Z"
                  transform="translate(-465.305 -1419.281)"
                />
                <path
                  style="fill: #a57367;"
                  d="M556.24,1514.373a3.426,3.426,0,0,1,2.591-.707,44.652,44.652,0,0,1,5.715,1.946l-.12,1.588-5.369-.368Z"
                  transform="translate(-474.226 -1417.059)"
                />
              </g>
            </g>
          </svg>
        </div>
        <div class="box__ d-flex justify-content-center">
          <div class="box__title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="372.752"
              height="131.535"
              viewBox="0 0 372.752 131.535"
            >
              <defs>
                <filter
                  id="a"
                  x="0"
                  y="0"
                  width="67.152"
                  height="81.346"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="b" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="b" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter
                  id="c"
                  x="58.263"
                  y="0.438"
                  width="61.194"
                  height="80.207"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="d" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="d" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter
                  id="e"
                  x="104.787"
                  y="0.438"
                  width="75.125"
                  height="80.207"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="f" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="f" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter
                  id="g"
                  x="162.436"
                  y="0.438"
                  width="76.877"
                  height="80.207"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="h" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="h" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter
                  id="i"
                  x="221.928"
                  y="0"
                  width="78.717"
                  height="81.083"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="j" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="j" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <filter
                  id="k"
                  x="291.932"
                  y="0.438"
                  width="80.82"
                  height="80.207"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" input="SourceAlpha" />
                  <feGaussianBlur stdDeviation="3" result="l" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="l" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g transform="translate(-52.729 -52.659)">
                <g
                  style="filter: url(#a);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M103.346,75.394a15.038,15.038,0,0,0-2.234-1.665,27.282,27.282,0,0,0-3.943-2.059,34.182,34.182,0,0,0-5.038-1.708,22.036,22.036,0,0,0-5.52-.7q-4.994,0-7.447,1.84a6.086,6.086,0,0,0-2.453,5.169,5.314,5.314,0,0,0,.919,3.2A7.941,7.941,0,0,0,80.3,81.7a22.411,22.411,0,0,0,4.424,1.753q2.672.789,6.177,1.665,4.554,1.227,8.28,2.628a22.468,22.468,0,0,1,6.308,3.5,14.486,14.486,0,0,1,3.986,5.038,16.723,16.723,0,0,1,1.4,7.228A18.006,18.006,0,0,1,109,112.061a15.843,15.843,0,0,1-5.082,5.739,22.086,22.086,0,0,1-7.359,3.2A37,37,0,0,1,87.838,122a46.819,46.819,0,0,1-13.843-2.1,42.717,42.717,0,0,1-12.266-5.958l5.345-10.426a16.766,16.766,0,0,0,2.847,2.059,43.158,43.158,0,0,0,4.862,2.541A37.707,37.707,0,0,0,81,110.264a28.062,28.062,0,0,0,7.009.877q9.988,0,9.988-6.4a5.248,5.248,0,0,0-1.139-3.417,9.67,9.67,0,0,0-3.241-2.454,31.059,31.059,0,0,0-5.082-1.927q-2.98-.875-6.659-1.928a56.545,56.545,0,0,1-7.754-2.672,20.354,20.354,0,0,1-5.476-3.417,12.79,12.79,0,0,1-3.286-4.556,15.759,15.759,0,0,1-1.1-6.177,19.325,19.325,0,0,1,1.752-8.411,16.992,16.992,0,0,1,4.863-6.133,22.038,22.038,0,0,1,7.228-3.724,29.9,29.9,0,0,1,8.849-1.27,34.359,34.359,0,0,1,12.091,2.059,45.084,45.084,0,0,1,9.638,4.863Z"
                    transform="translate(-52.73 -52.66)"
                  />
                </g>
                <g
                  style="filter: url(#c);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M237,111.258v10.6H193.807V59.652h42.406v10.6H205.9V85.236h26.2v9.813H205.9v16.209Z"
                    transform="translate(-126.54 -53.21)"
                  />
                </g>
                <g
                  style="filter: url(#e);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M312.415,59.652l15.332,29.964,15.6-29.964H356.4l-22.6,40.478v21.729h-12v-21.9l-22.517-40.3Z"
                    transform="translate(-185.49 -53.21)"
                  />
                </g>
                <g
                  style="filter: url(#g);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M442.663,59.652l16.822,47.05,16.647-47.05h12.7l-24.27,62.207H454.4L429.959,59.652Z"
                    transform="translate(-258.52 -53.21)"
                  />
                </g>
                <g
                  style="filter: url(#i);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M595.136,121.742a28.251,28.251,0,0,1-12.4-2.672,30.425,30.425,0,0,1-9.55-7.1A32.912,32.912,0,0,1,564.821,90.2a31.254,31.254,0,0,1,2.322-11.916,32.89,32.89,0,0,1,6.4-10.075,30.532,30.532,0,0,1,9.681-6.966A28.751,28.751,0,0,1,595.4,58.659a27.318,27.318,0,0,1,12.31,2.76,30.757,30.757,0,0,1,9.506,7.228,33.293,33.293,0,0,1,6.133,10.163,32.007,32.007,0,0,1,2.19,11.565,31.458,31.458,0,0,1-2.278,11.872,32.253,32.253,0,0,1-6.308,9.988,30.347,30.347,0,0,1-9.637,6.922A28.736,28.736,0,0,1,595.136,121.742ZM577.087,90.2a24.993,24.993,0,0,0,1.227,7.8,20.808,20.808,0,0,0,3.548,6.659,17.629,17.629,0,0,0,5.695,4.644,17.641,17.641,0,0,0,15.421-.044,17.655,17.655,0,0,0,5.695-4.775,21.283,21.283,0,0,0,3.5-6.7A24.882,24.882,0,0,0,613.36,90.2a24.271,24.271,0,0,0-1.27-7.8,21.536,21.536,0,0,0-3.593-6.658,17.155,17.155,0,0,0-5.695-4.6,17.743,17.743,0,0,0-15.376.088,17.294,17.294,0,0,0-5.7,4.731,21.247,21.247,0,0,0-3.461,6.658A24.908,24.908,0,0,0,577.087,90.2Z"
                    transform="translate(-333.89 -52.66)"
                  />
                </g>
                <g
                  style="filter: url(#k);"
                  transform="matrix(1, 0, 0, 1, 52.73, 52.66)"
                >
                  <path
                    style="fill: #c64342;"
                    d="M774.244,121.859V80.68l-16.034,30.665H751.64L735.606,80.68v41.179H723.515V59.652h12.967l18.4,35.4,18.575-35.4h12.879v62.207Z"
                    transform="translate(-422.58 -53.21)"
                  />
                </g>
                <path
                  style="fill: #212121;"
                  d="M324.939,275.494a20.2,20.2,0,0,1,1.351-7.434,17.85,17.85,0,0,1,9.425-9.958,16.868,16.868,0,0,1,7.007-1.458,16.092,16.092,0,0,1,8.358,2.169,13.725,13.725,0,0,1,5.513,6.082l-1.423.5a13.569,13.569,0,0,0-5.229-5.37,14.574,14.574,0,0,0-7.433-1.956,14.881,14.881,0,0,0-6.331,1.352,16.433,16.433,0,0,0-5.121,3.7,17,17,0,0,0-3.451,5.549,19.289,19.289,0,0,0,.036,13.729,18.268,18.268,0,0,0,3.521,5.726A17.054,17.054,0,0,0,336.32,292a14.074,14.074,0,0,0,6.26,1.423,15.164,15.164,0,0,0,4.3-.641,17.685,17.685,0,0,0,3.983-1.707,12.953,12.953,0,0,0,3.095-2.49,7.465,7.465,0,0,0,1.707-2.917l1.423.427a10.481,10.481,0,0,1-2.027,3.45,14,14,0,0,1-3.308,2.774,17.485,17.485,0,0,1-4.233,1.85,16.8,16.8,0,0,1-4.731.675,16.1,16.1,0,0,1-6.9-1.529,17.978,17.978,0,0,1-5.691-4.161A20.141,20.141,0,0,1,326.361,283,19.834,19.834,0,0,1,324.939,275.494Z"
                  transform="translate(-147.101 -110.648)"
                />
                <path
                  style="fill: #212121;"
                  d="M433.612,259.356a14.6,14.6,0,0,0-9.069,3.414,16.759,16.759,0,0,0-5.3,8.75v23.332h-1.423V257.934h1.423v9.532a18.194,18.194,0,0,1,5.157-6.438,13.66,13.66,0,0,1,6.864-2.952q.71-.07,1.316-.107c.4-.023.747-.035,1.031-.035Z"
                  transform="translate(-199.01 -111.369)"
                />
                <path
                  style="fill: #212121;"
                  d="M477.372,294.842a15.871,15.871,0,0,1-6.864-1.529,17.839,17.839,0,0,1-5.655-4.2,20.6,20.6,0,0,1-3.841-6.189,20.52,20.52,0,0,1-.071-14.832,18.851,18.851,0,0,1,3.734-5.975,17.516,17.516,0,0,1,5.548-4.019,16.05,16.05,0,0,1,6.794-1.458,16.359,16.359,0,0,1,6.935,1.494,17.783,17.783,0,0,1,5.62,4.055,19.854,19.854,0,0,1,5.228,13.515v.783H461.011a20.007,20.007,0,0,0,1.672,6.651,18.5,18.5,0,0,0,3.592,5.371,16.628,16.628,0,0,0,5.086,3.592,14.46,14.46,0,0,0,6.082,1.316,15.3,15.3,0,0,0,4.161-.569,16.357,16.357,0,0,0,3.806-1.6,13.889,13.889,0,0,0,3.13-2.454,11.487,11.487,0,0,0,2.134-3.13l1.351.356a11.856,11.856,0,0,1-2.24,3.521,15.1,15.1,0,0,1-3.414,2.774,18.175,18.175,0,0,1-4.268,1.85A16.8,16.8,0,0,1,477.372,294.842Zm15.934-19.775a20.033,20.033,0,0,0-1.565-6.829,17.754,17.754,0,0,0-3.522-5.371,16.153,16.153,0,0,0-5.015-3.521,15.253,15.253,0,0,0-12.235,0,15.845,15.845,0,0,0-5.015,3.557,17.144,17.144,0,0,0-3.45,5.406,20.987,20.987,0,0,0-1.494,6.758Z"
                  transform="translate(-222.352 -110.648)"
                />
                <path
                  style="fill: #212121;"
                  d="M563.292,294.842a12.444,12.444,0,0,1-4.695-.889,11.84,11.84,0,0,1-3.806-2.419,11.294,11.294,0,0,1-2.525-3.592,10.65,10.65,0,0,1-.925-4.41,8.411,8.411,0,0,1,1.1-4.268,10.1,10.1,0,0,1,3.13-3.308,16.2,16.2,0,0,1,4.837-2.169,22.887,22.887,0,0,1,6.153-.783,40.192,40.192,0,0,1,6.473.533,30.686,30.686,0,0,1,5.9,1.529v-4.2a15.411,15.411,0,0,0-.854-5.228,11.273,11.273,0,0,0-2.454-4.054,11.13,11.13,0,0,0-3.841-2.6,12.853,12.853,0,0,0-4.944-.925,16.108,16.108,0,0,0-5.762,1.174,28.628,28.628,0,0,0-6.189,3.379l-.853-1.138q7.112-4.836,12.8-4.837a15.1,15.1,0,0,1,5.584,1,11.652,11.652,0,0,1,4.268,2.846,12.884,12.884,0,0,1,2.7,4.481,17.14,17.14,0,0,1,.96,5.9v20.416q0,1.423,1.138,1.422v1.423a2.146,2.146,0,0,1-1-.142,2.275,2.275,0,0,1-1.138-1.032,3.311,3.311,0,0,1-.427-1.671v-3.414a16.721,16.721,0,0,1-6.687,5.157A22.025,22.025,0,0,1,563.292,294.842Zm0-1.422a20.968,20.968,0,0,0,8.714-1.779,13.128,13.128,0,0,0,5.868-4.766,5.03,5.03,0,0,0,1.067-2.917v-7.327a33.988,33.988,0,0,0-12.377-2.205,23.045,23.045,0,0,0-5.656.64,13.927,13.927,0,0,0-4.339,1.85,9.025,9.025,0,0,0-2.81,2.881,7.155,7.155,0,0,0-1,3.735,9.324,9.324,0,0,0,.818,3.877,9.953,9.953,0,0,0,2.241,3.165,10.122,10.122,0,0,0,3.343,2.1A11.282,11.282,0,0,0,563.292,293.42Z"
                  transform="translate(-273.631 -110.648)"
                />
                <path
                  style="fill: #212121;"
                  d="M651.59,276.833a10.881,10.881,0,0,1-1.956,1.067,18.339,18.339,0,0,1-2.1.676,10.584,10.584,0,0,1-2.7.32,6.88,6.88,0,0,1-4.481-1.565,5.06,5.06,0,0,1-1.92-4.126V243.044h-5.335v-1.422h5.335V228.746h1.422v12.876h8.892v1.422h-8.892v30.161a4.067,4.067,0,0,0,1.636,3.166,5.487,5.487,0,0,0,3.343,1.1,9.978,9.978,0,0,0,2.276-.249,13.737,13.737,0,0,0,1.885-.569,8.3,8.3,0,0,0,1.28-.6,4.51,4.51,0,0,0,.534-.356Z"
                  transform="translate(-319.321 -95.057)"
                />
                <path
                  style="fill: #212121;"
                  d="M690.019,229.385v-5.477h1.422v5.477Zm0,46.451V238.918h1.422v36.918Z"
                  transform="translate(-351.134 -92.353)"
                />
                <path
                  style="fill: #212121;"
                  d="M726.9,294.852l-16.076-36.918h1.565l15.152,34.855,15.151-34.855h1.565l-16.076,36.918Z"
                  transform="translate(-362.759 -111.369)"
                />
                <path
                  style="fill: #212121;"
                  d="M812.939,294.842a15.871,15.871,0,0,1-6.864-1.529,17.844,17.844,0,0,1-5.655-4.2,20.608,20.608,0,0,1-3.841-6.189,20.521,20.521,0,0,1-.071-14.832,18.845,18.845,0,0,1,3.735-5.975,17.512,17.512,0,0,1,5.548-4.019,16.049,16.049,0,0,1,6.793-1.458,16.358,16.358,0,0,1,6.935,1.494,17.773,17.773,0,0,1,5.62,4.055,19.858,19.858,0,0,1,5.229,13.515v.783H796.578a20.017,20.017,0,0,0,1.672,6.651,18.514,18.514,0,0,0,3.593,5.371,16.628,16.628,0,0,0,5.086,3.592,14.46,14.46,0,0,0,6.082,1.316,15.308,15.308,0,0,0,4.162-.569,16.373,16.373,0,0,0,3.806-1.6,13.9,13.9,0,0,0,3.13-2.454,11.479,11.479,0,0,0,2.134-3.13l1.352.356a11.864,11.864,0,0,1-2.24,3.521,15.112,15.112,0,0,1-3.415,2.774,18.17,18.17,0,0,1-4.268,1.85A16.807,16.807,0,0,1,812.939,294.842Zm15.934-19.775a20.02,20.02,0,0,0-1.565-6.829,17.761,17.761,0,0,0-3.521-5.371,16.164,16.164,0,0,0-5.015-3.521,15.254,15.254,0,0,0-12.236,0,15.845,15.845,0,0,0-5.015,3.557,17.141,17.141,0,0,0-3.45,5.406,20.973,20.973,0,0,0-1.494,6.758Z"
                  transform="translate(-409.892 -110.648)"
                />
              </g>
            </svg>
          </div>
          <div class="box__line_1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="184"
              height="5"
              viewBox="0 0 184 5"
            >
              <line x2="179" transform="translate(2.5 2.5)" />
            </svg>
          </div>
          <div class="box__line_2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="229"
              height="5"
              viewBox="0 0 229 5"
            >
              <line x2="224" transform="translate(2.5 2.5)" />
            </svg>
          </div>
          <div class="box__text_1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="230"
              height="183"
              viewBox="0 0 230 183"
            >
              <text transform="translate(0 48)">
                <tspan x="0" y="0">Design</tspan>
                <tspan x="0" y="61">Website</tspan>
                <tspan x="0" y="122">Solutions</tspan>
              </text>
            </svg>
          </div>
          <div class="box__text_2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="91"
              height="183"
              viewBox="0 0 91 183"
            >
              <text transform="translate(91 48)">
                <tspan x="-90.95" y="0">WE</tspan>
                <tspan x="-83.5" y="61">DO</tspan>
                <tspan x="-47.3" y="122">IT</tspan>
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Index",
  mounted() {
    new this.$parallax(document.querySelector(".index__"));
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.index__ {
  height: 100%;
  position: relative;
  padding-top: 6em;
  margin-left: auto;
  margin-right: auto;
}
.box__ {
  padding-top: 5.5em;
}
.we__ {
  padding-top: 8.6em;
  position: absolute;
  width: 100%;
  z-index: 2;
  transform: translate(-210px, 0px);
  display: grid !important;
}
.box__line_1,
.box__line_2,
.box__text_1,
.box__text_2 {
  margin: 0;
  position: absolute;
}
.box__line_1 {
  transform: translate(-85px, 170px);
}
.box__line_2 {
  transform: translate(62px, 190px);
}
.box__text_1 {
  fill: #212121;
  font-size: 50px;
  font-family: Montserrat-Light, Montserrat;
  font-weight: 300;
  transform: translate(60px, 240px);
}
.box__text_2 {
  fill: #212121;
  font-size: 50px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
  transform: translate(-120px, 240px);
}

@media only screen and (max-width: 1800px) {
  .we__ {
    transform: translate(-190px, -0px);
  }
  .its__we {
    width: 370px;
  }
  .box__ {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: 1600px) {
  .we__ {
    transform: translate(-170px, -20px);
  }
  .its__we {
    width: 340px;
  }
  .box__ {
    transform: scale(0.7);
  }
}

@media only screen and (max-width: 1400px) {
  .we__ {
    transform: translate(-150px, -45px);
  }
  .its__we {
    width: 320px;
  }
}

@media only screen and (max-width: 1300px) {
  .we__ {
    transform: translate(-130px, -50px);
  }
  .its__we {
    width: 300px;
  }
  .box__ {
    transform: scale(0.6);
  }
}

@media only screen and (max-width: 1200px) {
  .we__ {
    transform: translate(-120px, -55px);
  }
  .its__we {
    width: 280px;
  }
  .index__ {
    padding-top: 9em;
  }
}

@media only screen and (max-width: 1100px) {
  .we__ {
    transform: translate(-110px, -55px);
  }
  .its__we {
    width: 240px;
  }
  .box__ {
    transform: scale(0.5);
  }
  .index__ {
    padding-top: 5em;
  }
}

@media only screen and (max-width: 992px) {
  .we__ {
    transform: translate(-100px, -65px);
  }
  .its__we {
    width: 220px;
  }
}

@media only screen and (max-width: 900px) {
  .we__ {
    transform: translate(-90px, -65px);
  }
  .its__we {
    width: 180px;
  }
  .box__ {
    transform: scale(0.4);
  }
}

/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .box__ {
    transform: scale(0.55);
    padding-top: 18em;
  }
  .its__we {
    width: 200px;
  }
  .we__ {
    justify-content: center !important;
    transform: unset;
    padding-top: initial;
  }
  .index__ {
    padding-top: 6.5em;
  }
  .box__line_1 {
    transform: translate(-85px, 150px);
  }
  .box__line_2 {
    transform: translate(62px, 170px);
  }
  .box__text_1 {
    transform: translate(60px, 210px);
  }
  .box__text_2 {
    transform: translate(-120px, 210px);
  }
}
@media only screen and (min-height: 740px) and (max-height: 840px) and (max-width: 767px) {
  .index__ {
    padding-top: 11em;
  }
}

@media only screen and (min-height: 420px) and (max-height: 740px) and (max-width: 767px) {
  .index__ {
    padding-top: 7em;
  }
}
@media only screen and (max-height: 640px) and (max-width: 767px) {
  .box__ {
    transform: scale(0.45);
    padding-top: 14.5em;
  }
  .its__we {
    width: 160px;
  }
  .index__ {
    padding-top: 7em !important;
  }
}
@media only screen and (max-width: 400px) {
  .box__ {
    transform: scale(0.5);
    padding-top: 16em;
  }
  .its__we {
    width: 180px;
  }
}

@media only screen and (max-width: 320px) {
  .index__ {
    padding-top: 5em;
  }
}
</style>
