import $ from 'jquery';

(function() {
	"use strict";
    /*[pan and scroll_container CSS scrolls]*/
	var pnls = document.querySelectorAll('.panel--').length,
		scdir, hold = false;

	var slength, plength;
	function _scrollY(obj) {
			var pan, step = 100,
			vh = window.innerHeight / 100,
			vmin = Math.min(window.innerHeight, window.innerWidth) / 100;
		if ((this !== undefined && this.id === 'scroll_container') || (obj !== undefined && obj.id === 'scroll_container')) {
            pan = this || obj;
			plength = parseInt(pan.offsetHeight / vh);
		}
		if (pan === undefined) {
			return;
		}
		plength = plength || parseInt(pan.offsetHeight / vmin);
		slength = parseInt(pan.style.transform.replace('translateY(', ''));
		if (scdir === 'up' && Math.abs(slength) < (plength - plength / pnls)) {
			slength = slength - step;
		} else if (scdir === 'down' && slength < 0) {
			slength = slength + step;
		} else if (scdir === 'top') {
			slength = 0;
		}
		if (hold === false) {
			hold = true;
			pan.style.transform = 'translateY(' + slength + 'vh)';
			var num = Math.abs(slength/100); 
			setActiveSection(num);
			setTimeout(function() {
				hold = false;
			}, 1000);
		}
		//console.log(scdir + ':' + slength + ':' + plength + ':' + (plength - plength / pnls));
	}
	/*[swipe detection on touchscreen devices]*/
	function _swipe(obj) {
		var swdir,
			sX,
			sY,
			dX,
			dY,
			threshold = 100,
			/*[min distance traveled to be considered swipe]*/
			slack = 50,
			/*[max distance allowed at the same time in perpendicular direction]*/
			alT = 500,
			/*[max time allowed to travel that distance]*/
			elT, /*[elapsed time]*/
			stT; /*[start time]*/
		obj.addEventListener('touchstart', function(e) {
			var tchs = e.changedTouches[0];
			swdir = 'none';
			sX = tchs.pageX;
			sY = tchs.pageY;
			stT = new Date().getTime();
			//e.preventDefault();
		}, false);

		obj.addEventListener('touchmove', function(e) {
			e.preventDefault(); /*[prevent scrolling when inside DIV]*/
		}, false);

		obj.addEventListener('touchend', function(e) {
			var tchs = e.changedTouches[0];
			dX = tchs.pageX - sX;
			dY = tchs.pageY - sY;
			elT = new Date().getTime() - stT;
			if (elT <= alT) {
				if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
					swdir = (dY < 0) ? 'up' : 'down';
				}
				if (obj.id === 'scroll_container') {
					if (swdir === 'up' && obj.style.transform !== 'translateY(-300vh)') {
						scdir = swdir;
						_scrollY(obj);
					} else if (swdir === 'down' && obj.style.transform !== 'translateY(0)') {
						scdir = swdir;
						_scrollY(obj);
					}
					e.stopPropagation();
				}
			}
		}, false);
	}
	/*[click button CSS scrolls]*/
	function _scrollY_btn(numSection){
		var vh = window.innerHeight / 100,
		vmin = Math.min(window.innerHeight, window.innerWidth) / 100;
		slength = numSection;
		var scroll_container = document.querySelector('.scroll_container');
		plength = parseInt(scroll_container.offsetHeight / vh);
		plength = plength || parseInt(scroll_container.offsetHeight / vmin);
		scroll_container.style.transform = 'translateY(' + slength + 'vh)';
		hold = true;
		var num = Math.abs(slength/100);
		setActiveSection(num);
		setTimeout(function() {
			hold = false;
		}, 1000);
	}
	/*[active choosed section]*/
	function setActiveSection(currentSectionNum){
		var panel = document.querySelectorAll('.panel--');
		panel[currentSectionNum].classList.add("active");
		if(currentSectionNum > 0){
			$("a#scrollToTopBtn").fadeIn("slow");
			$("a#scrollDownBtn").fadeOut("slow");
		}else {
			$("a#scrollToTopBtn").fadeOut("slow");
			$("a#scrollDownBtn").delay(1000).fadeIn("slow");
		}
		panel.forEach(function(e) {
			if(panel[currentSectionNum] == e){
				var contactSection = e.querySelector(".contacts__");
				var idTabLink = e.id.toLowerCase();
				activeCurrentTab(idTabLink != "home" ? document.querySelector("a#"+idTabLink) : null);
				var navbarBtnLetsTalk = document.querySelector(".btn-lets__talk");
				var navbarBtnLetsTalkDummy = document.querySelector(".btn-lets__talk__");
				var menuNavbarBtnLetsTalk = document.querySelector(".menu-item__lets-talk");
				var menuNavbarBtnLetsTalkDummy = document.querySelector(".menu-item__lets-talk_");

				if(contactSection != null){
					navbarBtnLetsTalk.classList.add("btn-hidden-lets__talk");
					menuNavbarBtnLetsTalk.classList.add("btn-hidden-lets__talk");
					setTimeout(function() {
						navbarBtnLetsTalk.style.display= "none";
						navbarBtnLetsTalkDummy.style.display= "block";
						menuNavbarBtnLetsTalk.style.display= "none";
						menuNavbarBtnLetsTalkDummy.style.display= "block";
					}, 1000);
				}else {
					navbarBtnLetsTalk.style.display= "block";
					navbarBtnLetsTalkDummy.style.display= "none";
					menuNavbarBtnLetsTalk.style.display= "block";
					menuNavbarBtnLetsTalkDummy.style.display= "none";
					navbarBtnLetsTalk.classList.remove("btn-hidden-lets__talk");
					menuNavbarBtnLetsTalk.classList.remove("btn-hidden-lets__talk");
				}
				e.classList.remove("deactivate");
			}else{
				e.classList.remove("active");
				e.classList.add("deactivate");			
			}
		});
	}
	/*[active current tab]*/
	function activeCurrentTab(obj){
		if(obj != null){
			obj.parentElement.classList.add("current");
			obj.parentElement.parentElement.childNodes.forEach(function(e) {
				if(e != obj.parentElement ){
					e.classList.remove("current");
				}
			});
		}
		else
		{
			document.querySelector("ul.navbar-links").childNodes.forEach(function(e) {
				e.classList.remove("current");
			});
		}
	}

	/*[assignments]*/
    var scroll_container = document.getElementById("scroll_container");
	scroll_container.style.transform = 'translateY(0)';
	scroll_container.addEventListener('wheel', function(e) {
		if (e.deltaY < 0) {
			scdir = 'down';
		}
		if (e.deltaY > 0) {
			scdir = 'up';
		}
		//e.stopPropagation();
	});
	
	scroll_container.addEventListener('wheel', _scrollY);

	document.querySelector("a#home").addEventListener("click", function(){ 
		_scrollY_btn(0);
		activeCurrentTab(null);
	});
	document.querySelector("a#biography").addEventListener("click", function(){ 
		_scrollY_btn(-100);
		activeCurrentTab(this);
	});
	document.querySelector("a#projects").addEventListener("click", function(){ 
		_scrollY_btn(-200);
		activeCurrentTab(this);
	});
	document.querySelector("a#contacts").addEventListener("click", function(){ 
		_scrollY_btn(-300);
		activeCurrentTab(this);
	});
	document.querySelector("a#scrollToTopBtn").addEventListener('click', function() {
		scdir = 'top';
		_scrollY(scroll_container);
	});
	document.querySelector("a#scrollDownBtn").addEventListener('click', function() {
		_scrollY_btn(-100);
		activeCurrentTab(this);
	});

	/** sidebar */
	$(".toggle-side").click(function() {
		this.classList.toggle('show');
		$(".side-menu").toggleClass("show");
	});

	$("a#home_").click(function() {
		$(".toggle-side").removeClass("show");
		$(".side-menu").toggleClass("show");
		_scrollY_btn(0);
		activeCurrentTab(null);
	});

	$("a#biography_").click(function() {
		$(".toggle-side").removeClass("show");
		$(".side-menu").toggleClass("show");
		_scrollY_btn(-100);
		activeCurrentTab(this);
	});
	$("a#projects_").click(function() {
		$(".toggle-side").removeClass("show");
		$(".side-menu").toggleClass("show");
		_scrollY_btn(-200);
		activeCurrentTab(this);
	});
	$("a#contacts_").click(function() {
		$(".toggle-side").removeClass("show");
		$(".side-menu").toggleClass("show");
		_scrollY_btn(-300);
		activeCurrentTab(this);
	});
	function SidebarCloseWindowSize() {
		if(window.innerWidth > 767){
			$(".side-menu").removeClass("show");
			$(".toggle-side").removeClass("show");
		}
	}
	window.onresize = SidebarCloseWindowSize;
	/** end sidebar */


	/*[inizialitation]*/
	_swipe(scroll_container);
	/* automatically scrolls on the home page */
	$('html, body').animate({ scrollTop: 0 }, 1000);
	/**show scroll down button with fadein */
	$("a#scrollDownBtn").delay(1000).fadeIn("slow");
})();